.Dealers-participantes-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
}
.Dealers-participantes-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
}
.extra-div {
  width: 156px;
  border: 2px solid #c3002f;
  margin-top: 10px;
}
.span-div-divvv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form-span-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem !important;
}
.Area-span {
  font-family: "Nissan barnd Regular";
  font-size: 18px !important;
  color: black;
  text-align: center !important;
}
.AutoGrupo-all-span {
  font-family: "Nissan barnd Regular";
  font-size: 18px !important;
  color: black;
  text-align: center;
}
.Dealers-participantes-span {
  font-weight: 500;
  color: black;
  font-size: 35px;
  text-align: center;
}

.Centro-span {
  font-family: "Nissan barnd Regular";
  font-weight: 300;
  font-size: 18px !important;
  color: black;
  text-align: center;
}
.margin-div {
  margin-bottom: 5rem;
}
.top-div {
  margin-top: 6rem;
}
.top-sec-div {
  margin-top: 1rem;
}
.new_heading {
  text-align: center;
  margin-top: 0px !important;
}

@media screen and (max-width: 430px) {
  .Dealers-participantes-span {
    font-size: 20px;
  }
}
