.loder_div {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: aqua; */
  background-image: url("../../assets/images/Loading.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
