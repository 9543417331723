.main_content_container_swiper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-height: 100%;
}
.main_heading_text_sec3 {
  width: 100%;
}

.sec3_slider {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  height: 80% !important;
}
#mySwiper_sec3 {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}
.sec3_slider > img {
  width: 100%;
  aspect-ratio: 4/2;
  object-fit: cover;
}
/* .swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  background-image: url("../../../../assets/images/right_arrow_icon.svg") !important;
  background-position: center;
  background-size: 100%;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  background-image: url("../../../../assets/images/left_arrow_icon.svg") !important;
  background-position: center;
  background-size: 100%;
} */
.heading_text_sec3 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  font-family: "Nissan barnd Regular";
  z-index: 9999;
}
.last_div_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* position: absolute;
  bottom: 1%; */
  margin-bottom: 5rem;
  gap: 1rem;
}
.sliderimg {
  display: block;
  width: 100% !important;

  object-fit: contain !important;
  aspect-ratio: 3/2 !important;
}
.ending_text_sec3 {
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  /* font-size: 20px !important; */
  /* font-weight: 400 !important; */
  /* line-height: 15px !important; */
  font-family: "Nissan barnd Light";
}
.button_div {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 90%;
  height: 100%;
}
.button_div > div {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  height: 100%;
}
.button_div > div > button {
  width: 30px;
  height: 30px;
  border: 1px solid #666666;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 950px) {
  .last_div_box {
    margin-bottom: 0rem !important;
  }
}

@media screen and (max-width: 550px) {
  .heading_text_sec3 {
    font-size: 11px !important;
    line-height: 15px !important;
  }
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    color: #040404 !important;
    font-size: 19px;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    color: #000000 !important;
    font-size: 19px;
  }
  .ending_text_sec3 {
    font-size: 8px !important;
  }
  .button_div > div > button {
    width: 20px;
    height: 20px;
  }
  .button_div > span {
    font-size: 12px;
  }

  .button_div > div {
    gap: 0.5rem;
  }
  .main_content_container_swiper {
    position: static !important;
  }

  .main_heading_text_sec3 {
    position: static !important;
  }
  .last_div_box {
    position: static !important;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 325px) {
  .heading_text_sec3 {
    font-size: 11px;
    line-height: 15px;
  }
  .ending_text_sec3 {
    font-size: 8px !important;
  }
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    color: #040404 !important;
    font-size: 19px;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    color: #000000 !important;
    font-size: 19px;
  }
  .main_content_container_swiper {
    position: static;
  }

  .main_heading_text_sec3 {
    position: static !important;
  }
  .last_div_box {
    position: static !important;
    margin-bottom: 1rem;
  }
}

.main_content_container_swiper {
  position: relative;
}
.main_heading_text_sec3 {
  position: absolute;
  z-index: 99;
  top: 10%;
}
.last_div_box {
  position: absolute;
  z-index: 99;
  bottom: -15%;
}
