#khaini {
  background-color: #292d32;
  width: 100%;
  height: fit-content;
  /* background-image: url('../Assets/Images/wrapperkiaimg.png'); */
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  padding: 1rem 1.5rem;
}

#secondw1 {
  background-color: #000000;
  width: 100%;
  min-height: 120vh;
  /* height: auto !important; */
  /* background-image: url('../Assets/Images/wrapperkiaimg.png'); */
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  padding: 1rem 1.5rem;
}

#secondw2 {
  background-color: #ffffff;
  width: 100%;
  min-height: 120vh;
  /* height: auto !important; */
  /* background-image: url('../Assets/Images/wrapperkiaimg.png'); */
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  padding: 1rem 1.5rem;
}

.wrapper2 {
  background-color: #000000;
  width: 100%;
  min-height: 120vh;
  /* height: auto !important; */
  /* background-image: url('../Assets/Images/wrapperkiaimg.png'); */
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  padding: 1rem 1.5rem;
}

@media screen and (max-width: 990px) {
  #secondw {
    padding: 1rem 1.5rem;
  }
}
@media screen and (max-width: 540px) {
  #secondw {
    min-height: 165vh;
  }
}
