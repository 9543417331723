.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: "Nissan barnd Regular";
  src: url(./Ariya/components/UI/font/Nissan\ Brand\ Regular.otf);
}
@font-face {
  font-family: "Nissan barnd Italic";
  src: url(./Ariya/components/UI/font/Nissan\ Brand\ Italic.otf);
}
@font-face {
  font-family: "Nissan barnd Bold";
  src: url(./Ariya/components/UI/font/Nissan\ Brand\ Bold.otf);
}
@font-face {
  font-family: "Nissan barnd Light";
  src: url(./Ariya/components/UI/font/Nissan\ Brand\ Light.otf);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.login_model {
  background-color: black !important;
}
.Tittle_text {
  font-size: 20px;
  font-family: "Nissan barnd Bold";
  padding-left: 30px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* @media only screen and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }
} */

/* @media only screen and (max-width: 767px) {
  body {
    orientation: portrait;
  }
} */
