.Main-card {
  max-height: max-content;
  max-width: max-content;
  background-color: #ffffff !important;
  border-radius: 22px !important;
  box-shadow: 10px 12px 4px 6px rgba(0, 0, 0, 0.4);
  padding: 4rem 3rem;
}

#card-head {
  font-size: 35px;

  font-family: "Nissan barnd Bold";
}

.card-body {
  padding: 0 !important;
  flex: none !important;
}

.cardbody {
  display: flex;
  flex-direction: column;
  width: 100% !important;
}

@media screen and (max-width: 540px) {
  #card-head {
    font-size: 20px;
  }

  .Main-card {
    padding: 2rem 1.5rem;
  }
}
