.sec6 {
  background-color: white;
  max-height: 100%;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 2rem;
}

.loadingg {
  color: #ffffff;
}

.bar-img {
  min-width: 40% !important;
  width: 55% !important;
  top: 0;
  right: 0;
  z-index: 0;
  display: none;
}

.rightsideee {
  z-index: 10000;
  width: 55%;
}

.leftsideeee > div > span {
  color: black;
}

.res-sec {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  flex: 1;
}

.Heading-sec6 {
  font-weight: 700;
  font-size: 32px;
  padding-bottom: 10px;
  border-bottom: 5px solid #1e60ba;
}

.width-sec-color {
  width: auto;
}

.sec-6-price {
  font-weight: 700;
  font-size: 20px;
}

.color-change-car-svg {
  width: 30px;
}

.img-width-div {
  width: 70%;
}

.car-img {
  position: relative;
  z-index: 2;
  width: 100%;
}

.leftsideeee {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

/* .cbtn2{
      display: flex;
      gap: 2.5rem;
      width: 100%;
    }
     */

.namecar {
  width: 270px;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1.3rem;
}

.namecar > :nth-child(2) {
  font-weight: 200;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  letter-spacing: -0.500211px;
  color: black;
  font-family: "Nissan barnd Regular" !important;
}

.cbtn2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}
.cbtn2 > .cobtn {
  width: 24px;
  height: 24px;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid white;
  outline: none;
  border-radius: 50%;
  opacity: 1;
  display: grid;
  place-items: center;
}

.btn-svg {
  border-radius: 100% !important;
  background-color: transparent !important;
  border: none;
}

.btnssss {
  display: flex;
  width: 100%;
}

.rightsideee > img {
  width: 100%;
  z-index: -1;
}

.rightsideee {
  z-index: 1;
}

.btnssss > button {
  background: #d40000;
  border-radius: 11px;
  width: 210px;
  height: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  outline: none;
  border: none;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.500211px;
  color: #ffffff;
  cursor: pointer;
  font-family: "Nissan barnd Regular" !important;
}

.colorsection {
  height: 100vh;
  max-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--darkblack);
}

.last_text {
  padding-top: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.last_text > P {
  color: black;
  font-size: 10px;
  display: flex;
  align-items: self-start;
  justify-content: flex-start;
  text-align: start;
  gap: 1rem;
  width: 90%;
  font-family: "Nissan barnd Regular" !important;
}

.last_text > P > span > svg {
  width: 20px;
  color: black !important;
}
/* .icon_info {
  color: black !important;
} */

@media screen and (max-width: 835px) {
  .width-sec-color {
    width: 100%;
  }

  .sec6 {
    height: 100%;
  }

  .res-sec {
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }

  .img-width-div {
    width: 100%;
  }

  .bar-img {
    top: 16rem;
    right: 6rem;
  }

  .sec6 {
    min-height: 100% !important;
    padding: 2rem 0;
  }

  .rightsideee {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 540px) {
  .cbtn2 > .cobtn {
    width: 16px;
    height: 16px;
  }
  .btnssss > button {
    width: 120px;
    height: 40px;
  }
  .sec6 {
    height: 100%;
  }
  .Heading-sec6 {
    font-size: 20px !important;
  }
  .sec-6-price {
    font-size: 16px;
  }
  .leftsideeee {
    gap: 0.5rem;
  }

  .cbtn2 {
    gap: 1rem;
  }
  .namecar > :nth-child(2) {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 428px) {
  .img-width-div {
    width: 480px;
    /* border: 1px solid red; */
  }
  .cbtn2 > .cobtn {
    width: 26px;
    height: 26px;
  }
  .rightsideee {
    width: 450px;
  }

  /* .colorsection{
    margin-top: 6rem; 
  } */
  .bar-img {
    width: 290px !important;
    right: 1rem;
  }

  .bar-img {
    top: 13rem;
    right: 60px;
    /* border: 1px solid red; */
  }
}

@media screen and (max-width: 376px) {
  .rightsideee > img {
    width: 100%;
  }

  .rightsideee {
    width: 100%;
  }
}
