.SideBarIcon {
  padding: 8px;
  border-radius: 100%;
}

.SideBarIconActive {
  background-color: #d9d9d9 !important;
}

.SideBarTxt {
  font-family: "Nissan barnd Bold";
  color: black;
  font-weight: 600;
  font-size: 20px;
}

.LogOut-btns-parent-div {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  .SideBarTxt {
    display: none;
  }
}

@media screen and (max-width: 540px) {
  .LogOut-btns-parent-div {
    flex-direction: column-reverse;
    gap: 20px;
  }
}
