/*  */
.heading_text_p {
  font-family: "Nissan barnd Regular";
}
.heading_text_p_mobile {
  display: none;
}
@media screen and (max-width: 428px) {
  .heading_text_p {
    display: none;
  }
  .heading_text_p_mobile {
    display: block;
    font-family: "Nissan barnd Regular";
  }
}
