.Espesification-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  font-family: "Nissan barnd Regular";
  color: #000000;
  font-weight: 500;
}

.padding-left-for-specs-data-txt {
  padding-left: 2.5rem !important;
}
.padding-left-for-specs-data-txt span {
  font-family: "Nissan barnd Regular";
}

.Accordian-item-personal-class {
  border: none !important;
  background-color: transparent !important;
}

.divider-div-for-espesification-data-divs {
  border-top: 1px solid #efefef;
}

.Accordion-header-personal-class > .accordion-button:not(.collapsed)::after {
  background-position: center !important;
  background-image: url("../../../assets/images/DownArrowAcc.svg") !important;
}

.Accordion-header-personal-class > .accordion-button::after {
  background-position: center !important;
  background-image: url("../../../assets/images/DropRightarrowacc.svg") !important;
}

.Accordion-header-personal-class > .accordion-button {
  color: #000000 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  font-size: 22px !important;
  padding-bottom: 0;
  font-family: "Nissan barnd Regular";
}

.Espesification-all-data-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.Espesification-data-div {
  width: 100%;
  height: 100%;
}

.mainmodelcontent > .modelpic {
  /* width: 700px; */
  width: 100%;
  align-self: center;
}

.mainmodelcontent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  max-width: 1200px;
  margin: auto;
  width: 100%;
  height: 100%;
  padding: 1rem 1rem;
}

/* .modelpic > img {
    width: 100%;
  } */

.modelscrollbar {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}

.modelpic {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arosimg {
  width: 63% !important;
}

.interiorimg {
  width: 82.5% !important;
}

.interiorimgg {
  width: 73.5% !important;
}
.interiorimg2 {
  width: 83.5% !important;
}

.modelimg {
  width: 80% !important;
  padding-top: 10px;
}

.modelimgdiv {
  width: 100%;
  /* height: 328.5px; */
  height: 33rem;
  display: grid;
  place-items: center;
}

.sixty > a > img {
  width: 50px;
  cursor: pointer;
}

.bhaismajhnhi {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* gap: 1rem; */
}

.namemodel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: max-content;
}

.reservebtn > button:disabled {
  background-color: gray;
}

.namemodel > span {
  font-family: "Nissan barnd Regular";
  font-weight: 400;
  font-size: 34px;
  text-align: center;
  letter-spacing: -0.500211px;
  color: #000000;
}

.namemodel > h5 {
  font-family: "Nissan barnd Regular";
  font-weight: 400;
  font-size: 34px;
  color: #000000;
}
.namemodel > h5 > span {
  font-family: "Nissan barnd Regular";
  font-size: 26px !important;
}
.namemodel > h5 {
  font-family: "Nissan barnd Regular";
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1px;
}
.namemodel > h5 > svg {
  width: 30px;
}
.namemodel > h5 > p {
  z-index: 99999;
  cursor: pointer;
}

.navinterior {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.buttonreserve {
  width: 100%;
}

.navinterior > span {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.12421px;
  color: #000000;
  cursor: pointer;
  padding-bottom: 5px;
  font-family: "Nissan barnd Regular";
}
.activespan {
  border-bottom: 2px solid #1e60ba;
}

.linediv4 {
  background-color: #1e60ba;
  width: 3px;
  height: 20px;
}
.colorsdiv > div {
  display: flex;
  gap: 1rem;
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
}

.colorsdiv {
  width: 100%;
  height: 100%;
}
.colorsdiv > div > button {
  width: 30px;
  height: 30px;
  font-family: "Nissan barnd Regular";
  border: 1px solid #666666;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  outline: none;
}

.colorsdiv > div > button > img {
  width: 100%;
}
.colorsdiv > div > button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.colorsdiv {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.colorsdiv > div {
  width: 100%;
}

.colorsdiv > span {
  font-family: "Nissan barnd Regular";
  font-weight: 200;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: -0.500211px;
  color: #000000;
}

.spicificationdiv {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.spicificationdiv > h4 {
  font-weight: 600;
  font-size: 30px;
  line-height: 48px;
  text-align: start;
  letter-spacing: -0.500211px;
  font-family: "Nissan barnd Regular";
  color: #000000;
  margin: 0;
  padding: 0;
}

.spicificationdiv > div > li {
  font-family: "Nissan barnd Regular";
  font-weight: 300;
  font-size: 25px;
  letter-spacing: -0.500211px;
  color: #000000;
  width: 100%;
}

.reservebtn {
  width: 100%;
  padding-bottom: 2rem;
}

.reservebtn > button {
  background: #c3002f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 200px;
  height: 50px;
  outline: none;
  border: none;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -0.500211px;
  font-family: "Nissan barnd Regular";
  color: #ffffff;
}

.spicificationdiv > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.sixty {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sixty > span > svg {
  width: 30px;
}
.sixty > span {
  cursor: pointer;
}

.spicificamaindiv {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 2rem;
  width: 100%;
}

.comparediv {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 1px !important;
  width: max-content !important;
}

.comparediv > :nth-child(2) {
  font-weight: 200;
  font-size: 14px;
  font-family: "Nissan barnd Regular";
  letter-spacing: -0.500211px;
  color: #000000;
}

@media screen and (max-width: 768px) {
  .mainmodelcontent > .modelpic {
    width: 100%;
  }

  .namemodel > span {
    font-size: 34px;
  }

  .namemodel > h5 {
    font-size: 34px;
  }

  .modelimgdiv {
    height: 26rem;
  }
}

@media screen and (max-width: 540px) {
  .spicificationdiv > li {
    font-size: 20px;
  }
  .spicificationdiv > h4 {
    font-size: 20px;
  }
  .sixty > span > svg {
    width: 25px;
  }
  .namemodel > h5 > span {
    font-size: 20px !important;
  }

  .colorsdiv > div {
    flex-wrap: wrap;
    gap: 10px;
  }
  .colorsdiv > span {
    font-size: 16px;
  }
  .Espesification-div {
    font-size: 16px;
  }

  .colorsdiv > div > button {
    width: 27px;
    height: 27px;
  }

  .modelimgdiv {
    /* height: 238.5px; */
    height: 18rem;
  }
  .Accordion-header-personal-class > .accordion-button::after {
    background-position: center !important;
    background-image: url("../../../assets/images/mobileDropRightarrow.svg") !important;
  }
  .Accordion-header-personal-class > .accordion-button:not(.collapsed)::after {
    background-position: center !important;
    background-image: url("../../../assets/images/mobiledownarrow.svg") !important;
  }
}

@media screen and (max-width: 440px) {
  .namemodel > span {
    font-size: 20px;
  }
  .Accordion-header-personal-class > .accordion-button {
    font-size: 16px !important;
    font-weight: 400 !important;
  }

  .namemodel > h5 {
    gap: 5px;
  }
  .spicificamaindiv {
    gap: 1rem;
  }
  .namemodel > h5 {
    font-size: 20px !important;
  }
  .modelimgdiv {
    /* height: 164.5px; */
    height: 14rem;
  }

  .spicificationdiv > div > li {
    font-size: 16px;
    list-style: none;
  }

  .namemodel > h5 > svg {
    width: 20px;
  }
  .mainmodelcontent {
    padding: 2rem 1rem;
  }

  .mainmodelcontent > :nth-child(1) {
    left: 20px;
  }
  .navinterior > span {
    font-size: 14px;
  }

  .reservebtn > button {
    width: 100%;
    font-size: 20px;
  }
  .reservebtn {
    width: 50%;
  }
  /* .colorsdiv > div > button {
      width: 25px;
      height: 18px;
    } */
}

@media screen and (max-width: 393px) {
  .navinterior {
    gap: 6px;
  }
  .modelimgdiv {
    /* height: 145px; */
    height: 12rem;
  }
}

@media screen and (max-width: 325px) {
  .mainmodelcontent {
    padding: 2rem 1rem;
  }
  .navinterior {
    gap: 10px;
  }
  .spicificationdiv > div > li {
    font-size: 12px;
  }
}
