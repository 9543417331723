.width-div-Gallery {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.two-img-in-line-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
}

.verdiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: max-content;
  max-width: 1200px;
  margin: auto;
  flex: 1;
  gap: 1rem;
  padding: 2rem 2rem;
}

.verbtn {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.verbtn > button {
  width: 192px;
  height: 48px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  border: none;
  outline: none;
  color: #ffffff;
}

.verbtn > :nth-child(1) {
  background-color: #c3002f;
}
.verbtn > :nth-child(2) {
  background-color: #9f9f9f;
}

.verdiv > p {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.500211px;
  color: #ffffff;
  width: 50%;
}

.model {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  z-index: 10000;
}

.model > :nth-child(1) {
  position: relative;
  top: 50px;
  z-index: -1;
}

.model > .closeicon > :nth-child(2) {
  animation: some 0.7s;
  width: 800px !important;
  border-radius: 50px;
}
.model > .closeicon > :nth-child(1) {
  animation: close 0.8s;
  cursor: pointer;
  align-self: flex-end;
}

@keyframes some {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.model > .closeicon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.closeicon > div > img {
  width: 100%;
}

@keyframes close {
  0% {
    transform: rotate(150deg);
    opacity: 0;
  }
  100% {
    transform: rotate(0deg);
    opacity: 1;
  }
}
.LOADimg {
  opacity: 0.5;
  border-radius: 10px;
  -webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
  animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-delay: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: animation-c7515d;
}

@media screen and (max-width: 1080px) {
  .model > .closeicon > :nth-child(2) {
    width: 100% !important;
  }
  .verdiv {
    flex-direction: column;
  }

  .verdiv > P {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .model > .closeicon > :nth-child(1) {
    padding-right: 10px;
    padding-bottom: 10px;
  }
  .model {
    padding: 0 1rem;
  }
  .model > .closeicon > :nth-child(2) {
    border-radius: 20px;
  }
}

@media screen and (max-width: 540px) {
  .model > :nth-child(1) {
    width: 100%;
  }
  .model > .closeicon > :nth-child(2) {
    width: 100%;
  }
  .two-img-in-line-gallery {
    gap: 1rem;
  }
  .width-div-Gallery {
    gap: 1rem;
  }

  .verdiv {
    padding: 1rem 2rem;
  }

  .verbtn {
    flex-direction: column;
  }
}
