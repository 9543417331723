#container1 {
  position: relative;
  width: 100vw;
  height: 100vh;
  box-shadow: 15px 18px 4px 6px rgba(255, 253, 253, 0.733) !important;
  /* top: 80px;/ */
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: url(../../../../assets/images/banner1.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 100%; */
}

#container1 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  bottom: 10%;
}

.playpousebtn {
  position: absolute;
  z-index: 1;
  top: 10%;
  right: 5%;
  background-color: rgba(218, 218, 218, 0.5);
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.pfbtns {
  display: flex !important;
  /* border: 1px solid red !important; */
}

.fullscreen {
  position: absolute;
  z-index: 1;
  top: 18%;
  right: 5%;
  background-color: rgba(218, 218, 218, 0.5);
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.playpousebtn:hover,
.fullscreen:hover {
  background-color: rgba(255, 0, 0, 0.747);
}
.fullscreen > img {
  width: 100%;
  transform: scale(0.9);
}

.playpousebtn > img {
  z-index: 1;
  width: 100%;
  transform: scale(1.5);
}

.playpousebtn > svg {
  width: 30px;
}

/* body::-webkit-scrollbar {
    display: none;
  } */
body::-webkit-scrollbar {
  width: 8px;
}

.Details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.3rem;
  position: absolute;
  top: 42%;
  left: 3%;
  padding: 0 1rem;
  width: 70%;
}

.Details > div {
  width: 681px;
}

.Details > div > p {
  font-family: "Nissan barnd Regular";
  width: 100%;
  font-weight: 700;
  font-size: 20px;
  line-height: 39px;
  letter-spacing: -0.656526px;
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.Details > h4 {
  font-family: "Nissan barnd Regular";
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -0.500211px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0 !important;
}

.Details > h1 {
  font-weight: 400;
  font-size: 64px;
  line-height: 77px;
  letter-spacing: -0.500211px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .Details {
    top: 50%;
  }

  .fullscreen {
    top: 20%;
  }
}

@media screen and (max-width: 835px) {
  .Details > p {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
  }

  .fullscreen {
    top: 15%;
  }

  #container1 > img {
    object-fit: cover;
    position: absolute;
    bottom: 0%;
  }

  #container1 {
    height: 100vh;
    padding-top: 1.32rem;
  }

  .Details {
    left: 0;
    top: 20%;
  }

  .Details > div {
    width: 100%;
  }

  .Details > h4 {
    font-size: 20px;
  }

  .Details > h1 {
    font-size: 30px;
    line-height: 35px;
  }
}

@media screen and (max-width: 550px) {
  .Details > div > p {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
  }
  #container1 {
    height: max-content;
    padding: 5rem 0rem;
  }
  #container1 > img {
    object-fit: contain;
  }

  .playpousebtn {
    top: 10%;
  }

  .fullscreen {
    top: 23%;
  }
  .playpousebtn > svg {
    width: 20px;
  }

  .playpousebtn,
  .fullscreen {
    width: 25px;
    height: 25px;
    margin-top: 10px;
  }
  .Details > h4 {
    font-size: 16px;
    line-height: 20px;
  }

  .Details > h1 {
    font-size: 20px;
    line-height: 20px;
  }

  .Details {
    left: 0%;
    top: 20%;
  }
}

@media screen and (max-width: 325px) {
  .Details {
    top: 17%;
  }

  .Details > h4 {
    font-size: 14px;
    line-height: 10px;
  }

  .Details > h1 {
    font-size: 16px;
    line-height: 15px;
  }
}
