.CanceledOrder-cars-divs-parent-div {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  gap: 20px;
}

.wasd-wasd-wasd {
  height: 600px !important;
  overflow: scroll;
}
