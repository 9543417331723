.mainbtn > .red {
  background: #c3002f;
  border-radius: 18.375px;
  min-width: 219px;
  width: fit-content;
  height: 49px;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Nissan barnd Regular";
  font-weight: 500;
  font-size: 15.0736px;
  line-height: 21px;
  border: none;
  outline: none;
  text-transform: uppercase;
}

.mainbtn > button:disabled {
  background-color: gray;
}

.reserbtn {
  background: #c3002f;
  border-radius: 18.375px;
  font-family: "Nissan barnd Regular";
  min-width: 219px;
  width: fit-content;
  height: 49px;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 500;
  font-size: 17.0736px;
  line-height: 21px;
  border: none;
  outline: none;
  text-transform: uppercase;
}

.reserbtn2 {
  font-family: "Nissan barnd Regular";
  font-weight: 500;
  font-size: 25px;
  letter-spacing: 0.035em;
  line-height: 21px;
  background-color: #c3002f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  width: 204px;
  height: 50px;
  border: none;
  outline: none;
  color: #ffffff;
}

.mainbtn > .white {
  background: #ffffff;
  border-radius: 18.375px;
  width: 219px;
  height: 49px;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  color: #000000 !important;
  font-weight: 500;
  font-size: 17.0736px;
  line-height: 21px;
  font-family: "Nissan barnd Regular";
  border: none;
  outline: none;
  text-transform: uppercase;
}

.mainbtn > .black {
  background: #000000;
  border-radius: 18.375px;
  width: 219px;
  height: 49px;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  color: #ffffff !important;
  font-family: "Nissan barnd Regular";
  font-weight: 500;
  font-size: 17.0736px;
  line-height: 21px;
  border: none;
  outline: none;
  text-transform: uppercase;
}

.mainbtn > button:disabled {
  background-color: #9f9f9f !important;
  cursor: not-allowed !important;
}

.box-shadow-wasd {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.btn-sec6 > button {
  background: #c3002f;
  border-radius: 18.375px;
  width: 150px;
  height: 45px;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Nissan barnd Regular";
  font-weight: 500;
  font-size: 17.0736px;
  line-height: 21px;
  border: none;
  outline: none;
}

/* checkbox css */

.cart-btns-div {
  gap: 3rem !important;
}

@media screen and (max-width: 540px) {
  .mainbtn > button {
    width: 280px;
  }
  .cart-btns-div {
    flex-direction: column !important;
    gap: 1rem !important;
  }

  .reserbtn2 {
    width: 200px !important;
  }
}

@media screen and (max-width: 470px) {
  .reserbtn {
    min-width: 100px;
    height: 40px;
    font-size: 12px;
    border-radius: 10px;
  }
  .reserbtn2 {
    width: 100px !important;
    height: 40px;
  }
  .mainbtn > button {
    font-size: 14px;
  }
}

/* @media screen and (max-width: 376px) {
   
  } */
