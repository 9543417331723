.cardetailpage {
  background-color: white;
  width: 100%;
  /* height: 100vh; */
  max-height: 100%;
  margin-top: 6rem;
  position: relative;
}

.modelselectdiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 15px 2rem;
  padding: 0 0.5rem;
  padding-top: 2rem;
  flex-wrap: wrap;
  /* position: absolute; */
  z-index: 99;
}

.modelselectdiv > span {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  letter-spacing: -0.500211px;
  color: #000000;
  padding-bottom: 10px;
  cursor: pointer;
}

.spanactive {
  border-bottom: 2px solid #8eafdc;
  width: 100px;
}

.allmo {
  width: 100%;
  position: relative;
}

@media screen and (max-width: 768px) {
  .modelselectdiv > span {
    font-size: 16px;
  }

  .modelselectdiv {
    /* gap: 1rem;
    justify-content: space-between; */
    width: 100%;
    padding: 2rem 2rem;
  }
}

@media screen and (max-width: 430px) {
  .modelselectdiv > span {
    font-size: 12px;
  }

  .spanactive {
    width: 60px;
  }

  .modelselectdiv {
    /* gap: 10px;
    padding: 2rem 1rem; */
  }

  .modelselectdiv > span {
    padding-bottom: 2px;
  }
}

@media screen and (max-width: 365px) {
  .modelselectdiv > span {
    font-size: 10px;
  }
}

@media screen and (max-width: 325px) {
  /* .modelselectdiv {
    padding: 2rem 1rem;
  } */
  .modelselectdiv > span {
    font-size: 10px;
  }
}
