/*  */
/* Modelos Info Modal css start */
h1,
h2,
h3,
p {
  font-family: "Roboto", sans-serif;
}

.info-dropdown > .dropdown-menu {
  /* width: 630px; */
  width: 100%;
  cursor: auto;
  padding: 30px !important;
  z-index: 111111111 !important;
  background-color: #292d32 !important;
  color: white !important;
  font-size: 13px !important;
  border-radius: 12px !important;
  text-align: center !important;
}

.Dropdown-Car-info-body-Head {
  font-size: 20px;
  padding-bottom: 1rem;
}

/* Modelos Info Modal css End */

.moulasdiv {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.swiperkhain {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.mySwiper {
  display: grid;
  place-content: center;
}

.moulasdivv {
  background-color: var(--darkblack);
  width: 100%;
  height: 100%;
  padding: 0rem 2rem;
  margin-top: 6rem;
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 2rem;
}

/* .cardmaindivv2{
    border: 1px solid red;
  } */

.swpier-wrapper {
  width: 100%;
  align-self: center;
}

.moulasdiv > span,
.moulasdivv > span {
  font-weight: 500 !important;
  font-size: 35px !important;
  line-height: 48px !important;
  letter-spacing: -0.500211px !important;
  color: #000000 !important;
  display: flex !important;
  align-items: flex-start !important;
  font-family: "Nissan barnd Regular";
}

.modulcard {
  background: #000000 !important;
  border-radius: 35px;
  width: 335px;
  height: auto !important;
  overflow: hidden;
  cursor: pointer;
}
.modulcard {
  background: #efefef !important;
  width: 100%;
  height: auto !important;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.46);
  border-radius: 35px;
}

.masinfo {
  /* background-color: #ffffff; */
  background-color: #ffffff !important;
  width: 100%;
  height: 30px;
  z-index: 1111;
  border: none !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: grid;
  place-items: center;
  position: relative;
  top: 10px;
  cursor: pointer;
}

.masinfo > span {
  font-family: "Nissan barnd Regular";
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.500211px;
  /* color: #000000; */
  color: #000000 !important;
}

.modulscontent {
  background: #000000 !important;
  /* border-radius: 35px; */
  width: 100%;
  height: 100%;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  /* border: 1px solid blue; */
}

/* .carimgdiv{
    width: 100%;
    display: grid;
    place-items: center;
  }
  
  .carimgdiv>img{
    width: 100%;
  } */
.mySwiper {
  width: 100%;
}
.cardimg > span {
  position: absolute;
  right: 20px;
  top: 15px;
}
.cardimg2 > span {
  position: absolute;
  right: 20px;
  top: 15px;
}
.cardmaindivv {
  display: flex;
  width: 50% !important;
  gap: 1rem;
}
.cardmaindivvv {
  display: none;
  width: 100%;
  gap: 1rem;
}

.cardmaindivv2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: auto;
}

.cardimg {
  width: 100%;
  height: 270px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.swiper-slide-shadow {
  box-shadow: none !important;
  border-radius: 30px;
}
.cardimg2 {
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.cardimg2 > img {
  /* border: 1px solid red; */
  width: 90%;
  object-fit: contain;
  aspect-ratio: 3/2;
}

.km {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.kmm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.first {
  display: flex;
  gap: 10px;
  align-items: center;
}

.first > span {
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #ffffff !important;
}

.modulscontent > :nth-child(1) {
  font-weight: 600;
  font-size: 30px;
  line-height: 43px;
  display: flex;
  align-items: center;
  letter-spacing: -0.500211px;
  color: #ffffff !important;
}

.mphdiv {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 2rem !important;
  top: 6.5rem !important;
  width: max-content;
  gap: 1rem;
}

.mphdiv2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.mphdiv > div > span,
.mphdiv2 > div > span {
  font-weight: 600 !;
  font-size: 30px !important;
  line-height: 56px;
  text-align: center;
  letter-spacing: -0.500211px;
  color: #ffffff !important;
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 0.5rem !important;
}

.kmfirst {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.pragraphs {
  font-size: 18px !important;
  font-weight: 200;
  margin: 0 !important;
  color: #ffffff !important;
}

.mph {
  display: flex;
  flex-direction: column;
}

.linediv3 {
  background-color: #1e60ba !important;
  width: 8px;
  height: 80px;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  color: #53545c !important;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  color: #53545c !important;
}

.cardimg > img,
.cardimg2 > img {
  width: 80%;
}
/* .swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  display: none;
} */

@media screen and (max-width: 1451px) {
  .modulcard,
  .modulcardd {
    width: 100%;
    /* height: 600px !important; */
  }

  .mySwiper {
    display: block;
  }
  .cardimg > img {
    width: 100%;
  }

  .cardimg2 > img {
    width: 75%;
  }

  .cardimg {
    height: 300px;
  }

  .mphdiv {
    top: 6rem;
  }

  .modulscontent {
    padding: 2rem 1rem;
  }
}

@media screen and (max-width: 1080px) {
  .lasttext > P {
    width: 100%;
  }

  .cardimg2 > img {
    width: 85%;
  }
}

@media screen and (max-width: 884px) {
  .info-dropdown > .dropdown-menu {
    width: 1000%;
  }
  .cardimg2 > img {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .cardmaindivvv {
    display: block;
    width: 100%;
    /* padding: 0 1rem; */
  }
  .moulasdiv > span,
  .moulasdivv > span {
    font-size: 20px !important;
  }

  .cardmaindivv {
    display: none !important;
  }

  .modulscontent > :nth-child(1) {
    font-size: 25px;
  }

  .mphdiv > div > span {
    font-size: 35px;
  }
  .mphdiv {
    gap: 10px;
  }

  .pragraphs {
    font-size: 25px;
  }
  .cardmaindivv2 {
    grid-template-columns: repeat(1, 1fr);
  }
  .cardimg2 > img {
    width: 90%;
  }
  /*  ~ */
}

@media screen and (max-width: 430px) {
  .cardimg {
    height: 220px;
  }
  .cardimg2 {
    height: 150px;
    padding: 10x;
  }

  .modulcard {
    height: auto !important;
    width: 100% !important;
  }

  /* .modulcardd {
      height: 460px !important;
      width: 100% !important;
    }
    .modulcard {
      height: auto !important;
      width: 100% !important;
    } */
}

@media screen and (max-width: 375px) {
  /* .modulcardd {
    height: auto !important;
    width: 100%;
  } */
}

@media screen and (max-width: 325px) {
  /* .modulcardd {
      height: 460px;
      width: 100%;
    } */
  .info-dropdown > .dropdown-menu {
    width: 900%;
    padding: 15px !important;
  }
}

@media screen and (max-width: 320px) {
  .info-dropdown > .dropdown-menu {
    width: 800%;
    padding: 15px !important;
  }
}
