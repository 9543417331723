.links-login {
  gap: 3rem;
  display: flex;
  justify-content: center;
  color: white;
  padding-top: 3px;
  padding-bottom: 3px;
}
.concesionario-span {
  font-size: 15px;
  font-weight: 300;
  color: black;
}

@media screen and (max-width: 430px) {
  .links-login {
    width: 290px;
    flex-direction: column;
    gap: 0.5rem !important;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 378px) {
  .concesionario-span {
    font-size: 12px;
  }

  .eresdiv {
    margin-top: -10px;
  }
}

@media screen and (max-width: 370px) {
  .links-login {
    width: 250px;
  }
}

@media screen and (max-width: 342px) {
  .links-login {
    width: 200px;
  }
}
