.high {
  height: 101px !important;
}

.Main-Sec-ContinuePayment {
  background-color: var(--darkblack);
  height: auto;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.bg-color {
  background-color: var(--darkblack);
}

.btn-space {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.text-ContinuePay {
  color: rgba(255, 255, 255, 0.7) !important;
  font-weight: 600;
}
.head-ContinuePay {
  font-family: "Nissan barnd Bold";
  width: 90%;
  /* font-weight: 700; */
  font-size: 30px;
}

.ContinuePay-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 100%;
  padding: 20px 0px;
}

.ModelName-Price-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 22px;
  font-family: "Nissan barnd Bold";
}

.dollor-txt {
  font-family: "Nissan barnd Regular";
  font-size: 16px;
}

.ContinuePay-body-image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContinuePay-body-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.Purchase-price-specs-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.price-txt {
  font-family: "Nissan barnd Regular";
  font-weight: 500;
  font-size: 22px;
}

.specs-div {
  font-family: "Nissan barnd Regular";
  display: flex;
  flex-direction: column;
  font-size: 15px;
}

.additionalSpecs {
  width: 100%;
}

.additionalSpecs-btn {
  padding: 8px 25px;
  border: none;
  border-radius: 30px;
  font-family: "Nissan barnd Regular";
  background-color: #53545c;
  color: #ffffffb2;
  font-weight: 500;
}

.SelectCard-parent-div {
  background-color: white;
  height: 100vh;
}

.PaymentBtn {
  font-family: "Nissan barnd Regular";
  background-color: #c3002f;
  border: none;
  border-radius: 24px;
  width: 100px;
  height: 40px;
  color: white;
  font-weight: 500;
  font-size: 16px;
}

.PaymentBtn-add {
  font-family: "Nissan barnd Regular";
  background-color: #c3002f;
  border: none;
  border-radius: 24px;
  width: auto;
  height: 40px;
  color: white;
  font-weight: 500;
  font-size: 16px;
  padding: 0px 15px;
}
.termsandpolicy {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}
.termsandpolicy > .link_of_trem {
  font-family: "Nissan barnd Regular";
  font-size: 15px;
  text-decoration: none;
  color: #212529;
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .media-querry {
    padding-top: 3rem !important;
  }
  .head-ContinuePay {
    font-family: "Nissan barnd Bold";
    /* font-weight: 650; */
    font-size: 32px;
  }
}

@media screen and (max-width: 884px) {
  .ContinuePay-body {
    flex-direction: column;
  }
}

@media screen and (max-width: 430px) {
  .head-ContinuePay {
    font-size: 20px;
    font-family: "Nissan barnd Bold";
    /* font-weight: 500; */
  }
  .additionalSpecs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .red_btn_Rpay {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
