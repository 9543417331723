* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.form-error {
  color: #c3002f !important;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0 !important;
  font-family: "Nissan barnd Regular";
}
.form-error-head {
  color: #c3002f !important;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 0 !important;
  font-family: "Nissan barnd Regular";
}

.carousel-control-next-icon::after {
  content: none !important;
}
.carousel-control-prev-icon::after {
  content: none !important;
}

.swiper {
  width: 100%;
  /* height: 100%; */
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 3/2;
}

/* 
body::-webkit-scrollbar {
  width: 8px;
  background-color: black;
}

body::-webkit-scrollbar-thumb {
  background-color: #c3002f;
  border-radius: 10px;
} */
body::-webkit-scrollbar {
  width: 8px;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1e60ba;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1e60bad0;
}

/* h1,
h2,
h3,
h4,
h5,
h6,
span,
input,
select {
  font-family: "Nissan barnd Regular";
} */
