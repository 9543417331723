.question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  transition: all 0.2s ease-in-out;
  width: 90%;
  flex: 1;
  cursor: pointer;
}
.faqdiv {
  padding: 2rem 0;
  /* top: 101px; */
}

.faqwrapper {
  width: 100%;
  height: 100%;
  /* overflow-y: scroll; */
  background-color: #ffffff;
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.questionback {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  color: #000000;
  width: 90%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #d40000;
  box-shadow: 8px 16px 4px rgba(0, 0, 0, 0.35);
  border-radius: 26px;
  padding: 0 1rem;
  cursor: pointer;
}
.questionback > div {
  width: 100%;
}

.hr {
  width: 156px;
  height: 0px;
  border: 1px solid #3e4247;
}

.venta {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  height: 100%;
  width: 100%;
}
.mainfaq {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 1rem;
  flex-direction: column;
}
.question > div {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.question > div > h3 {
  font-weight: 700;
  font-size: 18px !important;
  line-height: 29px;
  letter-spacing: -0.500211px;
  color: #000000;
  margin-bottom: 0 !important;
  display: inline;
  font-family: "Nissan barnd Regular";
}

.question > div > .content {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.500211px;
  width: 70%;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  font-family: "Nissan barnd Regular";
}

.questionback > div > h3 {
  font-weight: 700;
  font-size: 18px !important;
  line-height: 29px;
  letter-spacing: -0.500211px;
  color: #000000;
  margin-bottom: 0 !important;
  display: inline;
  font-family: "Nissan barnd Regular";
}

.contentshow {
  height: auto;
  max-height: 999px;
  color: #000000;
}

.svg {
  transform: rotate(90deg);
}

.pre {
  font-weight: 500;
  color: black;
  font-size: 35px;
  /* text-align: center; */
  font-family: "Nissan barnd Regular";
}

.question:hover {
  width: 90%;
  height: 140px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #d40000;
  box-shadow: 8px 16px 4px rgba(0, 0, 0, 0.35);
  border-radius: 26px;
  padding: 5px 1rem;
}
.newFAQ_div {
  width: 90%;
}
.newFAQ_div > h3 {
  cursor: pointer;
  color: #000;
  font-family: "Nissan barnd Regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.5px;
}
.main_faq_box_reguler {
  width: 100%;
  height: 100%;
  background-color: #000;
  border-radius: 22px;
  padding: 1rem 0.5rem;
}
.main_faq_box_reguler > h2 {
  color: #fff;
  text-align: center;
  font-family: "Nissan barnd Regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.5px;
  padding-bottom: 3rem;
}
.faq_div_text > ol {
}
.faq_div_text > ol > li {
  padding-bottom: 1rem;
  color: #fff;
  font-family: "Nissan barnd Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.5px;
}
@media screen and (max-width: 540px) {
  .hr {
    width: 156spx;
  }
  .question > div > h3 {
    font-size: 18px;
  }
  .question {
    width: 100%;
  }
  .questionback {
    width: 100%;
  }
  .questionback > div > h3 {
    font-size: 18px;
  }

  .question:hover {
    width: 100%;
  }

  .contentshow {
    font-size: 12px;
  }
  .faq_div_text > ol > li {
    font-size: 10px;
  }
  .main_faq_box_reguler > h2 {
    font-size: 15px;
  }
}
