#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: transparent;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}
/* 
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
}

.slide_img {
  display: flex;
  width: 100%;
} */

@media screen and (max-width: 550px) {
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    color: #000000 !important;
    font-size: 19px;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    color: #000000 !important;
    font-size: 19px;
  }
}
@media screen and (max-width: 325px) {
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    color: #040404 !important;
    font-size: 19px;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    color: #000000 !important;
    font-size: 19px;
  }
}
