.ProfileHead {
  font-family: "Nissan barnd Bold";

  font-size: 19px !important;
  font-weight: 600 !important;
}

.ProfileText {
  font-family: "Nissan barnd Regular";
  font-size: 20px !important;
  /* font-weight: 600 !important; */
  /* font-style: italic !important; */
}

.HeadText {
  font-family: "Nissan barnd Regular";
  font-weight: 500;
}

.nav-pills .nav-link {
  padding: 0 !important;
}

.profile-yes-div {
  gap: 3rem !important;
}

/* .new_class_add {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: center !important;
  gap: 12rem !important;
  margin-left: 2rem !important;
  padding-top: 2rem !important;
} */

@media screen and (max-width: 768px) {
  .ProfileHead {
    font-size: 16px !important;
    font-weight: 700 !important;
  }

  .ProfileText {
    font-size: 19px !important;
    font-weight: 600 !important;
    font-style: italic !important;
  }
}

@media screen and (max-width: 568px) {
  .profile-yes-div {
    flex-direction: column !important;
    gap: 1rem !important;
  }
}
