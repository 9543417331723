h3,
p {
  font-family: "Roboto", sans-serif;
}

.section5 {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.section5 > :nth-child(1) > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.section5 > :nth-child(1) {
  width: 100%;
  height: 100%;
}

.slidediv {
  display: none;
  /* cursor: pointer;
  position: absolute;
  left: 15%;
  transform: translateX(-15%);
  bottom: 20%;
  z-index: 20; */
}
.slidediv2 {
  display: none;
  /* cursor: pointer;
  position: absolute;
  right: 15%;
  transform: translateX(-15%);
  bottom: 20%;
  z-index: 20; */
}

.conntai {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conte {
  position: absolute;
  bottom: 0%;
  left: 15%;
  transform: translateX(-50%);
  z-index: 20;
}

.MainC {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  cursor: pointer;
}

.prahead > h3 {
  font-family: "Nissan barnd bold";
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: -0.500211px;
  color: #ffffff;
  padding: 0;
  margin: 0;
  padding-bottom: 5px;
}

.slideactive > h3 {
  font-family: "Nissan barnd Regular";
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: -0.500211px;
  color: #000000;
  padding: 0;
  margin: 0;

  padding-bottom: 2px;
}

.prahead {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* gap: 10px; */
  width: 269px;
  height: 170px;
  transition: all 0.1s ease-in-out;
  padding: 10px;
}

.slideactive {
  background: #ffffff;
  box-shadow: 8px -5px 4px rgba(0, 0, 0, 0.35);
  border-radius: 22px;
  width: 269px;
  /* height: auto !important; */
  color: #000000;
  padding: 20px;
}

.slideactive::-webkit-scrollbar {
  display: none;
}

.prahead::-webkit-scrollbar {
  display: none;
}

.prahead > div {
  width: 100%;
}

.prahead > div > p {
  font-family: "Nissan barnd Regular";
  font-weight: 600;
  font-weight: 400;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: -0.500211px;
  color: #ffffff;
  width: 100%;
  height: auto;
  text-align: start;
  margin: 0 !important;
  padding: 0;
  padding-top: 2px;
}

.slideactive > div > P {
  font-weight: 700;
  font-weight: 400;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: -0.500211px;
  color: #020202;
  width: 100%;
  text-align: start;
  padding-top: 0.5rem;
  margin: 0 !important;
  padding: 0;
  padding-top: 2px;
}

.borderactive {
  background: #1e60ba !important;
  width: 104px;
  height: 3px;
  position: relative;
  margin-bottom: 5px;
  margin-top: 5px;
}

.over {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

.overdataimg {
  position: relative;
}

@media screen and (max-width: 1600px) {
  .slidediv {
    left: 1%;
    transform: translateX(-15%);
  }
  .slidediv2 {
    right: 1%;
    transform: translateX(-15%);
  }
}

@media screen and (max-width: 1024px) {
  .section5 {
    width: 100%;
    height: 100%;
  }
  .slidediv {
    cursor: pointer;
    position: absolute;
    left: 0;
    bottom: 20%;
  }
  .MainC {
    gap: 1rem;
    padding: 0 1rem;
  }

  .prahead {
    height: auto;
    overflow-y: scroll;
  }

  .slideactive {
    height: auto;
    overflow-y: scroll;
  }

  .prahead > h3 {
    font-size: 16px;
  }

  .slideactive > h3 {
    font-size: 16px;
  }
  .prahead > p {
    height: 100%;
  }

  .slideactive > p {
    height: 100%;
  }

  .slidediv2 {
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 20%;
  }

  .conte {
    width: 100%;
    padding: 0rem 1rem;
  }
}

@media screen and (max-width: 768px) {
  .slidediv {
    display: none;
  }

  .slidediv2 {
    display: none;
  }

  .MainC {
    flex-direction: column;
    width: 100%;
  }

  .MainC > div {
    align-self: flex-start;
  }

  .prahead {
    width: 255px;
    height: max-content;
  }

  .slideactive {
    width: 255px;
    height: max-content;
  }
}

@media screen and (max-width: 950px) {
  .section5 {
    width: 100%;
    height: 100%;
  }
  .conte {
    width: 50% !important;
    height: 100vh;
    overflow-y: scroll;
    position: absolute;
    display: flex;
    align-items: center;

    bottom: 1% !important;
    left: 20% !important;
    transform: translateX(-50%) !important;
    z-index: 20;
    /* background-color: #1e60ba !important; */
  }
  .MainC {
    width: 100% !important;
    gap: 0rem;
    align-items: flex-end;
    justify-content: flex-end !important;
  }
  .prahead {
    width: 235px !important;
    height: 150px !important;
  }

  .slideactive {
    width: 235px !important;
    height: 150px !important;
  }
  .prahead > div > p {
    font-size: 10px !important;
    line-height: 12px !important;
  }

  .slideactive > div > P {
    font-size: 10px !important;
    line-height: 12px !important;
  }
  .conte::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (max-width: 540px) {
  .conte {
    width: 100% !important;
    height: 100vh;
    overflow-y: scroll;
    position: absolute;
    display: flex;
    align-items: center;

    bottom: 1% !important;
    left: 25% !important;
    transform: translateX(-30%) !important;
    z-index: 20;
    /* background-color: #1e60ba !important; */
  }
  .MainC {
    width: 100% !important;
    gap: 0rem;
    align-items: flex-end;
    justify-content: flex-end !important;
  }

  .prahead {
    width: 255px;
    height: 170px;
  }

  .slideactive {
    width: 255px;
    height: 170px;
  }

  /* .section5>:nth-child(1)>img{
         
    } */
}

@media screen and (max-width: 393px) {
  .prahead {
    width: 255px;
    height: 160px;
  }

  .slideactive {
    width: 255px;
    height: 140px;
  }

  .MainC {
    gap: 5px;
  }
}

@media screen and (max-width: 325px) {
  .prahead {
    width: 255px;
    height: 120px;
  }

  .slideactive {
    width: 255px;
    height: 120px;
  }
}
