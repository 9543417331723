.ContactUs-Main-sec {
  background-color: white;
  /* background: url("Image for background");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}

.top-top {
  /* position: relative; */
  height: 101px;
}

.height-div {
  min-height: 100vh;
  height: fit-content !important;
}

.Heading-contactUs {
  padding-top: 1rem;
  font-weight: 500;
  color: black;
  font-size: 30px;
  font-family: "Nissan barnd Regular";
  /* font-family: "Nissan barnd bold"; */
  text-align: center;
}
