.FooterTxt {
  color: #000000 !important;
  text-decoration: none;
  margin-bottom: 6px !important;
  font-family: "Nissan barnd Regular";
}
/*  */
.MainFooter {
  background-color: #efefef;
}

.FooterTextLinkParent > p {
  color: #ffffff;
  font-weight: 350;
}

.FooterTextLinkParent > p > a > *:hover {
  color: gray;
  transform: scale(1.4);
}

.FacebookIcon {
  width: 18px;
}
.pt-2 {
  padding-top: 1.5rem !important;
}

#disable {
  pointer-events: none;
  cursor: default;
}

#disable > a {
  color: grey !important;
}

.InstaIcon {
  width: 24px;
}

.LinkedinIcon {
  width: 24px;
}

.YoutubeIcon {
  width: 24px;
}

.TwitterIcon {
  width: 24px;
}

.w-80 {
  width: 85%;
}

.sociallinks > a > * {
  transition: all 0.2s ease-in;
  color: #000000;
}

.sociallinks > a {
  cursor: pointer;
}

.flogo {
  cursor: pointer;
}

.sociallinks > a > .InstaIcon:hover {
  transform: scale(1.3);
  color: #e7295c;
  /* color: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%); */
}

.sociallinks > a > .LinkedinIcon:hover {
  transform: scale(1.3);
  color: #0a66c2;
}

.sociallinks > a > .YoutubeIcon:hover {
  transform: scale(1.3);
  color: #ff0000;
}

.sociallinks > a > .TwitterIcon:hover {
  transform: scale(1.3);
  color: #55acee;
}

.sociallinks > a > .FacebookIcon:hover {
  transform: scale(1.3);
  color: #3b5998;
}

.footer-text-head {
  color: #000000;
  font-size: 20px !important;
  font-weight: 750 !important;
  font-family: "Nissan barnd Bold";
}

.footer-text {
  margin-bottom: 6px !important;
}

.FooterModalText {
  color: white;
  font-size: 13px;
  font-family: "Nissan barnd Regular";
}

@media screen and (max-width: 767px) {
  .down-footer {
    flex-direction: column-reverse !important;
  }
}
