.main_div {
  overflow-x: hidden;
  background-color: #000000;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub_div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
  /* padding: 3.5rem 0rem; */
}
.logo_box {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
}
.logo_img {
  width: 35%;
}

.splash_heding_text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 70%;
}
.spl_text1 {
  font-family: "Nissan barnd Regular";
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 35px;
  text-align: center;
  letter-spacing: -0.500211px;

  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
/* model card style start  */
.main_box_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.car_card_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}
.card_text {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 30px;
  letter-spacing: -0.500211px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
}
.card_img {
  width: 250px;
  height: 250px;
  box-shadow: #f5f5f559 0px 3px 8px;
  border-radius: 30px;
}

/* responsive style start */
@media screen and (max-width: 62em) {
  .sub_div {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;
    padding: 3rem 0 0 0;
  }
  .main_box_div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    gap: 1rem;
  }
  .logo_img {
    width: 30%;
  }

  .logo_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* height: 100vh; */
  }

  .splash_heding_text {
    width: 315px;
    gap: 0.3rem;
    padding-top: 3rem;
  }

  .card_img {
    width: 150px;
    height: 150px;
    box-shadow: #f5f5f559 0px 3px 8px;
    border-radius: 20px;
  }

  .card_text {
    font-size: 22px;
    line-height: 35px;
  }

  .spl_text1 {
    font-size: 14px;
    line-height: 25px;
  }

  .up-down {
    animation: up-down linear 4s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    -webkit-animation: up-down linear 4s;
    -webkit-animation-iteration-count: infinite;
    -webkit-transform-origin: 50% 50%;
    -moz-animation: up-down linear 4s;
    -moz-animation-iteration-count: infinite;
    -moz-transform-origin: 50% 50%;
    -o-animation: up-down linear 4s;
    -o-animation-iteration-count: infinite;
    -o-transform-origin: 50% 50%;
    -ms-animation: up-down linear 4s;
    -ms-animation-iteration-count: infinite;
    -ms-transform-origin: 50% 50%;
  }

  @keyframes up-down {
    0% {
      transform: translate(1px, 20px);
    }
    24% {
      transform: translate(1px, 30px);
    }
    50% {
      transform: translate(1px, 12px);
    }
    74% {
      transform: translate(1px, 22px);
    }
    100% {
      transform: translate(1px, 22px);
    }
  }

  @-moz-keyframes up-down {
    0% {
      -moz-transform: translate(1px, 20px);
    }
    24% {
      -moz-transform: translate(1px, 30px);
    }
    50% {
      -moz-transform: translate(1px, 12px);
    }
    74% {
      -moz-transform: translate(1px, 22px);
    }
    100% {
      -moz-transform: translate(1px, 22px);
    }
  }

  @-webkit-keyframes up-down {
    0% {
      -webkit-transform: translate(1px, 20px);
    }
    24% {
      -webkit-transform: translate(1px, 30px);
    }
    50% {
      -webkit-transform: translate(1px, 12px);
    }
    74% {
      -webkit-transform: translate(1px, 22px);
    }
    100% {
      -webkit-transform: translate(1px, 22px);
    }
  }

  @-o-keyframes up-down {
    0% {
      -o-transform: translate(1px, 20px);
    }
    24% {
      -o-transform: translate(1px, 30px);
    }
    50% {
      -o-transform: translate(1px, 12px);
    }
    74% {
      -o-transform: translate(1px, 22px);
    }
    100% {
      -o-transform: translate(1px, 22px);
    }
  }

  @-ms-keyframes up-down {
    0% {
      -ms-transform: translate(1px, 20px);
    }
    24% {
      -ms-transform: translate(1px, 30px);
    }
    50% {
      -ms-transform: translate(1px, 12px);
    }
    74% {
      -ms-transform: translate(1px, 22px);
    }
    100% {
      -ms-transform: translate(1px, 22px);
    }
  }
}
