.cart {
  position: relative;
  top: 30px;
}
h4 {
  font-family: "Nissan barnd Regular";
}

.cart-under-line {
  border-bottom: 2px solid #c3002f;
  width: 100px;
}
.Modal-text {
  font-family: "Nissan barnd Regular";
}

.cartdiv {
  /* min-width: 400px; */
  width: auto;
  height: 100px;
}
.cart_box {
  position: relative;
  cursor: pointer;
  height: 230px !important;
  flex-shrink: 0 !important;
  border-radius: 18.375px !important;
  background: #efefef !important;
  box-shadow: #00000059 0px 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.text_box_cart {
  display: flex;
  gap: 1rem;
  padding-right: 1rem;
}
.model_name_div {
  display: flex;
  flex-direction: column;
}
.sub_main_box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.model_info_div {
  color: #000;
  font-family: "Nissan barnd Regular";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.color_name_div {
  display: flex;
  flex-direction: column;
  /* width: 90%; */
}
.colour_name_span {
  color: #000;
  font-family: "Nissan barnd Regular";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.5px;
}

.txt-div-cart {
  width: 70%;
}

.cart-img-div {
  width: 226px;
  height: 155px;
}
.cart_img {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: cover;
}
.delete-btn {
  position: absolute;
  top: 15px;
  right: 10px;
}
.inner_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 95%;
}

@media screen and (max-width: 550px) {
  .cartdiv {
    flex-direction: column !important;
    padding: 15px 10px !important;
    height: auto !important;
    width: 100% !important;
  }
  .inner_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column !important;
    padding: 15px 10px !important;
    height: auto !important;
    width: 100% !important;
  }
  .txt-div-cart {
    width: 90%;
  }
  .text_box_cart {
    padding-right: 0;
    gap: 0;
  }
  .colour_name_span {
    font-size: 12px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 428px) {
  .txt-div-cart {
    width: 90%;
  }

  .cart-img-div {
    width: 225px;
  }

  .cartdiv {
    flex-direction: column !important;
    padding: 15px 10px !important;
    height: auto !important;
    width: 100% !important;
  }
  .cart_box {
    height: 100% !important;
  }
  .model_info_div {
    font-size: 15px;
  }
  .colour_name_span {
    font-size: 10px;
    margin-bottom: 0;
  }
  .text_box_cart {
    padding-right: 0;
  }
  .inner_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column !important;
    padding: 15px 10px !important;
    height: auto !important;
    width: 100% !important;
  }
  .sub_main_box {
    gap: 1rem;
  }
}
