.confirm-model-modal {
  padding: 20px 15px !important;
}

.Main-div-confirm-model-modal {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

.confirm-model-modal-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 22px;
  font-weight: 500;
  flex-direction: column;
  gap: 6px;
}

.underline-for-model-modal-header {
  border-bottom: 2px solid #8eafdc;
  width: 150px;
}

.confirm-model-modal-image-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
}

.confirm-model-modal-icons-parent-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.confirm-model-modal-icons-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  flex-direction: row;
}

.confirm-model-modal-icons {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.Name-and-Price-confirm-model-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.Name-and-Price-confirm-model-modal {
  color: black;
  font-size: 22px;
}

.specs-ext-int-confirm-model-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}

.specs-color-confirm-model-modal {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.Model-color-btn {
  width: 20px;
  height: 20px;
  border: 1px solid #666666;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  outline: none;
}

.deposite-div-confirm-model-modal {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
}

.btn-div-confirm-model-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

@media screen and (max-width: 884px) {
  .btn-div-confirm-model-modal {
    /* flex-direction: column; */
    gap: 1rem;
  }
  .confirm-model-modal-icons-div {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .underline-for-model-modal-header {
    width: 100px;
  }

  .btn-div-confirm-model-modal {
    flex-direction: column;
  }

  .confirm-model-modal-icons {
    width: auto;
  }

  .confirm-model-modal-icons-div {
    flex-wrap: wrap;
    gap: 1rem 2.5rem;
  }

  .confirm-model-modal-image-div {
    height: 200px;
  }
}

@media screen and (max-width: 375px) {
  .Name-and-Price-confirm-model-modal {
    flex-direction: column;
    gap: 10px;
  }

  .specs-color-confirm-model-modal {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
}
