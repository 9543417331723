.heading-policy {
  font-weight: 500;
  color: black;
  font-size: 35px;
  text-align: center;
}
/* .border-bottom-for-policy {
  width: fit-content;
  border-bottom: 2px solid #3e4247;
  padding-bottom: 1rem;
} */

.border-bottom-divider-policy {
  width: 156px;
  border-bottom: 2px solid #c3002f !important;
}

.privacy-sec {
  top: 40px !important;
}

@media screen and (max-width: 540px) {
  .heading-policy {
    font-size: 20px;
    padding: 0 !important;
  }
}
