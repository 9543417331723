#Galeria {
  -webkit-touch-callout: none;
  width: 100%;
  /* height: 100%; */
  background-color: white;
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: block;
}
.heding_text_span {
  width: 80%;
  padding-top: 3rem;
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.500211px;
  text-align: center;
  font-family: "Nissan barnd Regular";
}

.maindivimg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  gap: 1rem;
  transition: all 0.3s ease-in;
}

.container2 {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.gellerimaindiv {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.gelehead {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.500211px;
  color: #000000;
  text-align: center;
  padding-top: 1rem;
  margin: 0;
}

.viewarrowdiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 10px;
  /* cursor: pointer; */
}

.viewarrowdiv > div > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
  animation: someanima 1s linear infinite;
  position: relative;
}

.smajhniaraha {
  cursor: pointer;
}
/* .smajhniaraha{
    width: max-content;
  }
    */
@keyframes someanima {
  0% {
    bottom: 10px;
    opacity: 0;
  }

  100% {
    bottom: -5px;
    opacity: 1;
  }
}

.viewarrowdiv > div > div > img {
  width: 25px;
}

.viewarrowdiv > div > span {
  color: #000000;
}

/* .maindivimg > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: auto;
} */

.second_img_box > img {
  width: 100%;
}

/* .carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 45px !important;
  height: 45px !important;
  background: rgba(159, 159, 159, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  padding: 10px !important;
  display: grid !important;
  place-items: center !important;
} */

.carousel-control-prev-icon {
  background-image: url("../../../../assets/images/right_arrow_icon.svg") !important;
  background-position: center;
  background-size: 100%;
}

.carousel-control-next-icon {
  background-image: url("../../../../assets/images/left_arrow_icon.svg") !important;
  background-position: center;
  background-size: 100%;
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 50% !important;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  width: 30% !important;
  height: max-content !important;
  padding: 0;

  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 1 !important;
  transition: opacity 0.15s ease;
}

/* .maindivimg > :nth-child(1) > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 15px;
} */

.one_img_box {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
  gap: 1rem !important;
  flex: 1 !important;
  width: 30% !important;
  height: 94.5vh !important;
}
/* .one_img_box > img {
  width: 100%;
  height: 38%;
  border-radius: 15px;
} */

/* slider image css start here ================================================================== */

.first_img_box > p,
.second_img_box > p,
.thred_img_box > p,
.four_img_box > p,
.fifth_img_box > p,
.six_img_box > p,
.seven_img_box > p,
.eight_img_box > p,
.nine_img_box {
  color: white;
  font-family: "Nissan barnd Regular";
  padding-left: 20px;
}
.first_img_box {
  background-image: url("../../../../assets/images/Section2/1.png");
  width: 100%;
  height: 38%;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: end;
}
.second_img_box {
  background-image: url("../../../../assets/images/Section2/2.png");
  width: 100%;
  height: 38%;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: end;
}
.thred_img_box {
  background-image: url("../../../../assets/images/Section2/3.png");
  width: 100% !important;
  height: 73.5vh !important;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: end;
}
.four_img_box {
  background-image: url("../../../../assets/images/Section2/4.JPG");
  width: 100%;
  height: 38%;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: end;
}
.fifth_img_box {
  background-image: url("../../../../assets/images/Section2/5.JPG");
  width: 100%;
  height: 38%;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: end;
}
.six_img_box {
  background-image: url("../../../../assets/images/Section2/6.JPG");
  width: 100% !important;
  height: 73.5vh !important;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: end;
}

.Two_img_box {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 70% !important;
  /* height: 100vh !important; */
  border-radius: 35px !important;
}
.maindivimg > :nth-child(2) > img {
  object-fit: contain;
  width: 100%;
  border-radius: 15px !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.carousel-indicators {
  display: none !important;
}

.modal-width-height {
  height: 40rem !important;
  width: 80rem !important;
}

.modal-content {
  height: auto !important;
  width: auto !important;
}
.heading_text_h2 {
  padding-top: 1.5rem;
  font-weight: 500 !important;
  font-size: 35px !important;
  line-height: 48px !important;
  letter-spacing: -0.500211px !important;
  color: #000000 !important;
  display: flex !important;
  align-items: flex-start !important;
  font-family: "Nissan barnd Regular";
}
#Galeriambile {
  display: none;
}

@media screen and (max-width: 1920px) {
  .carousel-control-next,
  .carousel-control-prev {
    width: 9% !important;
  }
}

@media screen and (max-width: 1400px) {
  .maindivimg {
    gap: 1rem;
  }

  .one_img_box {
    height: 85.5vh !important;
  }

  .gellerimaindiv {
    height: 100%;
  }
  .carousel-control-next,
  .carousel-control-prev {
    width: 4% !important;
  }

  /* .maindivimg > :nth-last-child(1) > img {
    width: 90%;
  }

  .maindivimg > div > img {
    width: 90%;
  } */
}

@media screen and (max-width: 835px) {
  /* .maindivimg {
    flex-direction: column;
  }

  .one_img_box {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 70% !important;
    border-radius: 35px !important;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 8% !important;
  }
  .Two_img_box {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
    border-radius: 15px !important;
  }
  .maindivimg > :nth-child(1) > img {
    object-fit: contain;
    width: 30%;
  }

  .maindivimg > :nth-child(2) > img {
    object-fit: contain;
    width: 100%;
  } */
  .container2 {
    width: 100%;
  }
  .Two_img_box {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 90% !important;
    /* height: 100vh !important; */
    border-radius: 35px !important;
  }
  #Galeria {
    display: none;
  }
  #Galeriambile {
    display: block;
    -webkit-touch-callout: none;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 0rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: block;
  }

  .heding_text_span {
    width: 90%;
    padding-top: 3rem;
    color: #000000;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.500211px;
    text-align: center;
  }

  .first_img_box > p,
  .second_img_box > p,
  .thred_img_box > p,
  .four_img_box > p,
  .fifth_img_box > p,
  .six_img_box > p,
  .seven_img_box > p,
  .eight_img_box > p,
  .nine_img_box {
    font-size: 8px;
    padding-left: 10px !important;
  }
  .first_img_box,
  .second_img_box,
  .four_img_box,
  .fifth_img_box {
    width: 100% !important;
    /* height: 26vh !important; */

    aspect-ratio: 3/2;
    object-fit: cover;
  }

  .thred_img_box,
  .six_img_box {
    width: 100% !important;
    /* height: 26vh !important; */
  }
  .viewarrowdiv > div > span {
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 48px !important;
    letter-spacing: -0.500211px !important;
    color: #000000 !important;
    font-family: "Nissan barnd Regular";
  }
  .maindivimg > :nth-child(1) > img {
    width: 110px;
    height: 100px;
    aspect-ratio: 3/2;
    object-fit: cover;
  }
  .one_img_box {
    height: 0vh !important;
    gap: 1.5rem !important;
    flex-direction: row !important;
  }

  .heading_text_h2 {
    font-size: 20px !important;
    padding-top: 2rem;
  }

  .maindivimg > :nth-child(2) > img {
    width: 70%;
  }

  .maindivimg {
    gap: 1rem;
  }

  .gelehead {
    font-size: 30px;
  }

  .maindivimg > div {
    gap: 1rem;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 30px !important;
    height: 30px !important;
    z-index: -10 !important;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 12% !important;
  }

  .gelehead {
    padding-top: 0;
  }
}

@media screen and (max-width: 540px) {
  #Galeria {
    display: none;
  }
  #Galeriambile {
    display: block;
    -webkit-touch-callout: none;
    width: 100%;
    /* height: 100%; */
    background-color: white;
    padding: 0rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: block;
  }

  .heding_text_span {
    width: 90%;
    padding-top: 3rem;
    color: #000000;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.500211px;
    text-align: center;
  }

  .first_img_box > p,
  .second_img_box > p,
  .thred_img_box > p,
  .four_img_box > p,
  .fifth_img_box > p,
  .six_img_box > p,
  .seven_img_box > p,
  .eight_img_box > p,
  .nine_img_box {
    font-size: 8px;
    padding-left: 10px !important;
  }
  .first_img_box,
  .second_img_box,
  .four_img_box,
  .fifth_img_box {
    width: 100% !important;
    height: 26vh !important;

    aspect-ratio: 3/2;
    object-fit: cover;
  }

  .thred_img_box,
  .six_img_box {
    width: 100% !important;
    height: 26vh !important;
  }
  .viewarrowdiv > div > span {
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 48px !important;
    letter-spacing: -0.500211px !important;
    color: #000000 !important;
    font-family: "Nissan barnd Regular";
  }
  .maindivimg > :nth-child(1) > img {
    width: 110px;
    height: 100px;
    aspect-ratio: 3/2;
    object-fit: cover;
  }
  .one_img_box {
    height: 0vh !important;
    gap: 1.5rem !important;
    flex-direction: row !important;
  }

  .heading_text_h2 {
    font-size: 20px !important;
    padding-top: 2rem;
  }

  .maindivimg > :nth-child(2) > img {
    width: 70%;
  }

  .maindivimg {
    gap: 1rem;
  }

  .gelehead {
    font-size: 30px;
  }

  .maindivimg > div {
    gap: 1rem;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 30px !important;
    height: 30px !important;
    z-index: -10 !important;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 12% !important;
  }

  .gelehead {
    padding-top: 0;
  }
}

@media screen and (max-width: 325px) {
  .maindivimg > :nth-child(1) > img {
    width: 13%;
  }

  .maindivimg > :nth-child(2) > img {
    width: 25%;
  }

  .gelehead {
    font-size: 20px;
  }
}

/*  */
