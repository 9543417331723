.inputs5 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}

.mainform5 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4rem;
  width: 100% !important;
}

@media screen and (max-width: 540px) {
  .inputs5 {
    width: 100%;
  }
  /* .inputs5>div{
        width: 60%;
      } */
}
