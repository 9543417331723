.card_main_img_box > h3 {
  font-size: 12px;
  font-family: "Nissan barnd Regular";
}
.card_img_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

@media screen and (max-width: 540px) {
  .card_main_img_box > h3 {
    font-size: 9px;
    text-align: center;
    font-family: "Nissan barnd Regular";
  }
}
