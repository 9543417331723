.main_dox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  /* margin-top: 101px; */
  /* border: 1px solid gray; */
}
.Main_model a {
  text-decoration: none;
}
.model_img_box a {
  text-decoration: none;
}
.actvelink {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 3px solid #8eafdc;
}

.dropdown_main {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(128, 128, 128, 0.627);
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  z-index: 10000 !important;
  background-color: white;
}

.dropdown_sub {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.Main_model {
  width: 250px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border-right: 1px solid rgba(128, 128, 128, 0.528);
}
.carimg_main {
  width: 140px;
}
.headingtext {
  font-size: 20px;
  text-align: start;
  color: black;
  text-decoration: none;
  font-family: "Nissan barnd Regular";
}

.link_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Sub_model {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-right: 1rem;
}
.Sub_model_text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.model_img_box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.carimg {
  width: 130px;
}

.Sub_model_text h1 {
  font-size: 20px;
  font-family: "Nissan barnd Regular";
}
.title_text {
  font-size: 12px;
  font-family: "Nissan barnd Regular";
  color: black;
}
.sub_model_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 62em) {
  .dropdown_sub {
    flex-direction: column;
  }
  .model_img_box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-direction: column;
  }
  .Main_model {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-right: none;
    border-bottom: 1px solid rgba(128, 128, 128, 0.528);
  }

  .link_box {
    flex-direction: column;
  }
}
