.wraperdiv {
  background-color: white;
  width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}

.carddivmain {
  background: #efefef;
  box-shadow: 15px 18px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 22px;
  height: 100%;
  width: 1400px;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.carddivmain > div > h1 {
  font-weight: 600;
  font-size: 35px;
  line-height: 42px;
  letter-spacing: -0.500211px;
  color: #000000;
  border-bottom: 3px solid #d40000;
  width: max-content;
  padding-bottom: 10px;
}

.carddivmain > :nth-child(1) {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 1500px) {
  .carddivmain {
    width: 100%;
    height: 100%;
  }
  .wraperdiv {
    padding: 2rem 2rem;
  }
}

@media screen and (max-width: 540px) {
  .carddivmain > div > h1 {
    font-size: 20px;
    line-height: 20px;
  }
  .carddivmain {
    padding: 1.5rem 1.5rem;
  }
}

@media screen and (max-width: 325px) {
  .carddivmain > div > h1 {
    font-size: 20px;
    line-height: 42px;
  }
  .carddivmain {
    padding: 1rem 1rem;
  }
  .wraperdiv {
    padding: 1rem;
  }
}
