.OrderDetail-Main-parent-div {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.OrderDetail-kia-logo-div {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ConfirmOrder-Main-parent-div {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.ConfirmOrder-kia-logo-div {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ConfirmOrder-Body-parent-div {
  width: 90%;
}

.ConfirmOrder-Body-header-div {
  font-family: "Nissan barnd Bold";
  /* font-weight: 700; */
  font-size: 20px;
}

.ConfirmOrder-Body-content-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
}

.ContinuePay-body-image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ConfirmOrder-Body-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.Confirm-order-Specs-div {
  font-family: "Nissan barnd Regular";
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 15px;
}

.modal-btns-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 884px) {
  .ConfirmOrder-Body-content-div {
    flex-direction: column;
  }
}

@media screen and (max-width: 540px) {
  .modal-btns-div {
    flex-direction: column;
  }
  .ContinuePay-body-image {
    margin-right: 20px;
  }
}

@media screen and (max-width: 430px) {
  .ConfirmOrder-Body-header-div {
    font-size: 20px;
    /* font-weight: 500; */
  }
}
