#input {
  width: 506px;
  height: 58px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2px 16px;
  overflow: hidden;
  border: 1px solid black !important;
}
.input-- {
  border: 1px solid black !important;
  outline: none !important;
  width: 100%;
  /* height: 58px; */
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2px 16px;
  overflow: hidden;
}

.input > input::placeholder {
  font-weight: 100;
  font-size: 16px;
  line-height: 19px;
  color: #abafb1;
}

.input--textarea {
  border: 1px solid black !important;
  outline: none !important;
  width: 100%;
  /* height: 58px; */
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 16px;
  overflow: hidden;
}
.input--textarea > .input-field {
  border: none !important;
  outline: none !important;
  resize: none;
  height: 130px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
}
.input-- > .input-field {
  font-family: "Nissan barnd Regular";
  border: none !important;
  outline: none !important;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
}
.input--textarea > span {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #5e6366;
}
.input-- > span {
  font-family: "Nissan barnd Regular";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #5e6366;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

#input > span {
  font-family: "Nissan barnd Regular";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #5e6366;
}

#input > input {
  width: 474px;
  height: 32px;
  padding: 0px;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000;
}

/* dropdown css */

#input2 {
  width: 506px;
  height: 58px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2px 16px;
  overflow: hidden;
  border: 1px solid black !important;
}

#input2 > select {
  width: 100%;
  height: 32px;
  padding: 0px;
  border: none;
  outline: none;
  font-family: "Nissan barnd Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--darkblack);
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../../../assets/images/downarrrow.png");
  background-repeat: no-repeat;
  background-position: right;
  overflow: hidden;
}

#input2 > select > * {
  font-size: 18px;
}

#input2 > span {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #5e6366;
}

@media screen and (max-width: 540px) {
  #input {
    width: 100%;
  }
  #input2 {
    width: 100%;
  }

  #input > input {
    width: 100%;
    font-size: 12px;
  }
}
