.fistbox_img > p,
.secondbox_img > p,
.thirdbox_img > p,
.fourbox_img > p,
.sixbox_img > p {
  color: white;
  font-family: "Nissan barnd Regular";
  padding-left: 20px;
}
.fivebox_img > p {
  color: black;
  font-family: "Nissan barnd Regular";
  padding-left: 20px;
}
.fistbox_img {
  background-image: url("../../../../assets//images/Section2/1.jpeg");
  width: 100%;
  height: 38%;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: end;
}
.secondbox_img {
  background-image: url("../../../../assets//images/Section2/2.jpeg");
  width: 100%;
  height: 38%;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: end;
}
.thirdbox_img {
  background-image: url("../../../../assets/images/Section2/3.jpeg");
  width: 100% !important;
  height: 73.5vh !important;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: end;
}
.fourbox_img {
  background-image: url("../../../../assets//images/Section2/5.jpeg");
  width: 100%;
  height: 38%;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: end;
}
.fivebox_img {
  background-image: url("../../../../assets//images/Section2/4.png");
  width: 100%;
  height: 38%;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: end;
}
.sixbox_img {
  background-image: url("../../../../assets/images/Section2/6.png");
  width: 100% !important;
  height: 73.5vh !important;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: end;
}

/* @media screen and (max-width: 540px) {
  .heding_text_span {
    width: 90%;
    padding-top: 3rem;
    color: #000000;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.500211px;
    text-align: center;
  }
  .fistbox_img > p,
  .secondbox_img > p,
  .thirdbox_img > p,
  .fourbox_img > p,
  .fivebox_img > p,
  .sixbox_img > p {
    font-size: 8px;
    padding-left: 10px !important;
    margin-bottom: 5px !important;
  }
  .fistbox_img,
  .secondbox_img,
  .fourbox_img,
  .fivebox_img {
    width: 80% !important;
    height: 20vh !important;
    aspect-ratio: 3/2;
    object-fit: cover;
  }

  .thirdbox_img,
  .sixbox_img {
    width: 80% !important;
    height: 20vh !important;
  }
} */

@media screen and (max-width: 540px) {
  #Galeria {
    display: none;
  }
  #Galeriambile {
    display: block;
    -webkit-touch-callout: none;
    width: 100%;
    /* height: 100%; */
    background-color: white;
    padding: 0rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: block;
  }

  .heding_text_span {
    width: 90%;
    padding-top: 3rem;
    color: #000000;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.500211px;
    text-align: center;
  }
  .fistbox_img > p,
  .secondbox_img > p,
  .thirdbox_img > p,
  .fourbox_img > p,
  .fivebox_img > p,
  .sixbox_img > p {
    font-size: 8px;
    padding-left: 10px !important;
    /* margin-bottom: 5px !important; */
  }

  .first_img_box > p,
  .second_img_box > p,
  .thred_img_box > p,
  .four_img_box > p,
  .fifth_img_box > p,
  .six_img_box > p,
  .seven_img_box > p,
  .eight_img_box > p,
  .nine_img_box {
    font-size: 8px;
    padding-left: 10px !important;
  }
  /* .first_img_box,
  .second_img_box,
  .four_img_box,
  .fifth_img_box {
    width: 100% !important;
    height: 26vh !important;

    aspect-ratio: 3/2;
    object-fit: cover;
  } */
  .fistbox_img,
  .secondbox_img,
  .fourbox_img,
  .fivebox_img {
    width: 100% !important;
    height: 26vh !important;
    aspect-ratio: 3/2;
    object-fit: cover;
  }
  .thirdbox_img,
  .sixbox_img {
    width: 100% !important;
    height: 26vh !important;
  }
  /* 
  .thred_img_box,
  .six_img_box {
    width: 100% !important;
    height: 26vh !important;
  } */
  .viewarrowdiv > div > span {
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 48px !important;
    letter-spacing: -0.500211px !important;
    color: #000000 !important;
    font-family: "Nissan barnd Regular";
  }
  .maindivimg > :nth-child(1) > img {
    width: 110px;
    height: 100px;
    aspect-ratio: 3/2;
    object-fit: cover;
  }
  .one_img_box {
    height: 0vh !important;
    gap: 1.5rem !important;
    flex-direction: row !important;
  }

  .heading_text_h2 {
    font-size: 20px !important;
    padding-top: 2rem;
  }

  .maindivimg > :nth-child(2) > img {
    width: 70%;
  }

  .maindivimg {
    gap: 1rem;
  }

  .gelehead {
    font-size: 30px;
  }

  .maindivimg > div {
    gap: 1rem;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 40px !important;
    height: 40px !important;
    z-index: -10 !important;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 12% !important;
    top: 42% !important;
  }

  .carousel-control-prev {
    left: -2% !important;
  }
  .carousel-control-next {
    right: -2% !important;
  }
  .carousel-control-prev .gelehead {
    padding-top: 0;
  }
}
