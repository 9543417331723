#akhaini {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

#akhaini > :nth-last-child(1) {
  align-self: center;
}

/* #akhaini>div{
    color: #ffffff;
  } */

#akhaini > div {
  width: 946px;
}

#akhaini > div > P {
  font-weight: 400;
  font-size: 20px;
  width: 100%;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.500211px;
  /* color: rgba(255, 255, 255, 0.7); */
  color: #000000;
  font-family: "Nissan barnd Regular";
}

#akhaini > h1 {
  font-weight: 400;
  font-size: 40px;
  /* line-height: 48px; */
  text-align: center;
  letter-spacing: -0.500211px;
  color: #000000;
  margin-bottom: 0 !important;
  font-family: "Nissan barnd Bold";
}

.modal-header {
  border: none !important;
  background-color: white !important;
  border-top-left-radius: 22px !important;
  border-top-right-radius: 22px !important;
}
.modal-body {
  background-color: white;
  border-radius: 22px !important;
  padding: 0rem 3rem 1rem 3rem !important;
  /* width: 550px !important; */
}
.modal-content {
  background-color: white !important;
  border: none !important;
  border-radius: 27px !important;
  /* width: 550px !important; */
}
.modal-dialog-centered {
  justify-content: center !important;
}
.btn-close {
  /* background: transparent url("../../assets/images/close.png") !important; */
  background-repeat: no-repeat !important;
  box-shadow: none !important;
}
.Modal-text {
  font-family: "Nissan barnd Regular";
  font-weight: 300;
  font-size: 16px;
}

.modal-body::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 1060px) {
  #akhaini > div {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  #akhaini > div > P {
    font-size: 14px;
    margin-bottom: 0 !important;
  }

  #akhaini > div {
    width: 300px;
  }
  #akhaini > h1 {
    font-size: 20px;
  }

  #akhaini {
    grid-gap: 1rem;
  }
}

@media screen and (max-width: 492px) {
  .logout-modal-btns {
    flex-direction: column-reverse !important;
  }
  .cancle-modal-btns {
    flex-direction: column-reverse !important;
  }
}
