.gallerydiv {
  padding-top: 100px;
}

.gellnav {
  background-color: white;
  width: 100% !important;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.gelle {
  font-weight: 400;
  font-size: 30px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.500211px;
  color: #000000;
  margin-right: 2rem;
  font-family: "Nissan barnd Regular" !important;
}
.gellnav > ul {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.gellnav > ul > li {
  list-style: none;
}
.gellnav > ul > li > span {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 70px;
  letter-spacing: 0.12421px;
  color: #000000;
  background-color: transparent;
  cursor: pointer;
  font-family: "Nissan barnd Regular" !important;
}

.gellnav {
  padding: 0 4rem;
}

.borderactive2 {
  list-style: none;
  width: max-content;
  border-bottom: 3px solid #c3002f;
  padding-bottom: 8px;
}

.linediv {
  height: 40px;
  width: 5px;
  background-color: #c3002f;
}
.linediv2 {
  height: 25px;
  width: 5px;
  background-color: #c3002f;
}
/* .gellnav>ul>.noneactive2{
      list-style: none;
      width: max-content;
      border: none;
  } */

.buttonreserve {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
svg {
  vertical-align: top !important;
}

@media screen and (max-width: 835px) {
  .gellnav {
    padding: 0 1rem;
  }

  .gelle {
    margin-right: 1rem;
  }
}

@media screen and (max-width: 540px) {
  .gelle {
    font-size: 20px;
  }

  .gellnav > ul > li > a {
    font-size: 14px;
  }
}

@media screen and (max-width: 325px) {
  .gellnav > ul {
    gap: 0.5rem;
  }

  .linediv {
    height: 30px;
  }
}
