.inputs2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.mainform2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.mainform3 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  margin-left: 50px;
}

@media screen and (max-width: 990px) {
  .mainform3 {
    margin-left: 0px;
  }
}

@media screen and (max-width: 540px) {
  .inputs2 {
    width: 100%;
  }

  .inputs2 > div {
    width: 100%;
  }
}
