.comparition-top-main-parent-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 50px 0;
  flex-direction: column;
  gap: 3rem;
}

.comparition-top-main-parent-width-div {
  width: 90%;
  height: 100%;
}

.Comparition-main-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: #efefef;
  border-radius: 20px;
  padding: 1rem 2rem;
  box-shadow: 12px 13px 4px 6px rgba(0, 0, 0, 0.2);
}

.Comparition-head-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.Comparition-head-txt-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}

.Comparition-head-txt {
  color: #000000;
  font-size: 24px;
  font-weight: 500;
}

.border-bottom-for-compare-div {
  width: 80%;
  border-bottom: 3px solid #d40000;
}

.Comparition-body-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Comparition-body-width-div {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.Comparition-body-width-div-risponsive {
  display: none;
}

.Comparition-car-card-main-div {
  background-color: #ffffff;
  border-radius: 30px;
  height: auto;
  width: 100%;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.Comparition-car-card-head-div {
  width: 100%;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
}

.Comparition-car-card-body-div-leaf {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
}

.Comparition-car-card-body-icons-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
}

.compare-price-txt {
  font-size: 22px;
  color: #e46060;
  font-weight: 500;
}

.Comparition-car-card-body-image-div {
  width: 100%;
}

.comparition-data-body-div {
  width: 90%;
}

.carnamesss {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mainnames_leaf {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  place-content: center;
}

.g0o5 {
  display: flex;
  justify-content: space-between;
  width: 68%;
}

.g0o5 > span {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.500211px;
  color: #000000;
}

.mainnames > span {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.500211px;
  color: #000000;
}

.headingfeature {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.500211px;
  color: #000000;
  padding-bottom: 1rem;
}
.headingfeature2 {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.500211px;
  color: #000000;
}

.ocndnp > span {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.500211px;
  color: #000000;
}

.tablediv > div {
  display: flex;
  justify-content: space-between;
}

.tablediv {
  display: flex;
  flex-direction: column;
}

.ocndnp {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.tableone {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  /* gap: 1rem; */
}

.auroradiv_leaf {
  display: grid;
  border-bottom: 1px solid gray;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  place-content: center;
  width: 100%;
  padding: 0.5rem 0;
}

/* .auroradiv2 {
  display: flex;
  width: 89%;
  justify-content: space-between;
} */

.auroradiv_leaf > span {
  font-weight: 200;
  font-size: 14px;
  place-self: start;
  letter-spacing: -0.500211px;
  color: #000000;
  padding-left: 5px;
}

.khaintick {
  grid-column-end: 5;
}
.khaintick2 {
  grid-column-end: 4;
}

.battery {
  grid-column-end: 3;
  width: 100%;
  text-align: end;
}
.battery2 {
  grid-column-end: 4;
  width: 100%;
  text-align: center;
}
.battery3 {
  grid-column-end: 5;
  width: 100%;
  text-align: center;
}

.battery4 {
  grid-column-end: 3;
  width: 100%;
  text-align: center;
}

.awdspan {
  grid-column-end: 5;
  width: 100%;
  text-align: center;
}

.khainwalaspan {
  width: 100%;
  text-align: center;
}

.inputModel {
  width: 100%;
  border-bottom: 2px solid #d40000;
}

.inputModel > .selectTag {
  width: 100%;
  height: 32px;
  border: none;
  outline: none;
  color: #000000;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../../assets/images/DownArrowAcc.svg");
  background-repeat: no-repeat;
  background-position: right;
  overflow: hidden;
}

.Features-table-head-txt {
  font-weight: 600 !important;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.500211px;
  color: #000000;
}

@media screen and (max-width: 1080px) {
  .Comparition-body-width-div {
    display: none;
  }

  .Comparition-body-width-div-risponsive {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .mainnames > span {
    font-size: 14px;
  }

  .auroradiv > img {
    width: 10px;
  }

  .auroradiv > span {
    font-size: 12px;
  }

  .ocndnp > span,
  .g0o5 > span {
    font-size: 12px;
  }

  .featurecard {
    padding: 1rem 1rem;
  }

  .featureheading > h1 {
    font-size: 25px;
    line-height: 40px;
  }

  .featureheading > div {
    width: 100px;
  }

  .featurewrapper {
    padding: 1rem 1rem;
  }
}

@media screen and (max-width: 430px) {
  .mainnames > span {
    font-size: 11px !important;
  }
  .mainnames {
    width: 75%;
  }
  .span_text {
    font-size: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
  }
}

@media screen and (max-width: 325px) {
  .auroradiv > span {
    font-size: 10px;
  }

  .mainnames > span {
    font-size: 10px;
  }

  .mainnames {
    gap: 5px;
    width: 100%;
  }
}
