.inputs4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.inputs4 > h1 {
  text-align: start;
  width: 100%;
  font-family: "Nissan barnd Bold";
  font-size: 20px;
}

.mainform {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4rem;
  width: 100% !important;
}

.inputs4 > input {
  margin: 0 !important;
}

.inputs4 > div {
  width: 100%;
}
/* .tutaler{
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      width: 100%;
  } */

.tulerbtn {
  padding-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tutaler > h1 {
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.500211px;
  color: #000000;
  font-family: "Nissan barnd Bold";
}
.inputsdiv {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
  gap: 1rem;
}

.inputsdiv > div {
  width: 100%;
}
.inputsdiv > div > input {
  width: 100%;
}
.inputmaindivvv {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}
@media screen and (max-width: 540px) {
  .inputs4 {
    width: 100%;
  }
  .tutaler > h1 {
    font-size: 20px;
  }
}
