.input-- > select {
  width: 100%;
  height: 32px;
  padding: 0px;
  font-family: "Nissan barnd Regular";
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--darkblack);
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADgSURBVHgBndI/DoIwFAbw9/wTV72BR9Ab6C4DE5JIIicRT2A8gSHEoBMDgyNHkCNwBDejgT5LRARSivItbfraX76heDz7G0ZgAYJlaMoWWqRooHPyKZ+0QHMsSweI7HzKBw6/0BZjDPaYbnjLgC+zf5pWMZ7QWCrTTrqLB7HKkfDXpiIsvsfzd5csB88b9p69gGMTWdM6zDTVWwn8oP1H/0pA49xEXK+0hS3CECjqUjLXdTX6nlXiupdxgklQRRmxEQLuZJgQrEPLj8RYLShDZZgUFKFNWCNYRPm3hSYszQuDXafL8s6q6QAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: right;
  overflow: hidden;
}
