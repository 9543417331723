.carddivmainn {
  background: #ffffff;
  box-shadow: 15px 18px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 22px;
  height: 600px;
  width: 1400px;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.contratocontent {
  height: 340px;
  overflow-y: scroll;
}

.wraperdiv2 {
  background-color: #000000;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
}

.carddivmainn > div > h1 {
  font-family: "Nissan barnd Bold";
  font-weight: 600;
  font-size: 35px;
  line-height: 42px;
  letter-spacing: -0.500211px;
  color: #000000;
  width: max-content;
  /* padding-bottom: 10px; */
  margin-bottom: 0rem;
  width: 100%;
}

.pragrapscontrato {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.acueh1 {
  width: 100%;
}

#scrollcontent > p {
  font-family: "Nissan barnd Regular";
  font-weight: 300;
  font-size: 16px;
  color: #000000;
}
#scrollcontent > ul > li {
  font-family: "Nissan barnd Regular";
  font-weight: 300;
  font-size: 16px;
  color: #000000;
}

.checkboxcontrato {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.maincheckbox > label > p {
  font-family: "Nissan barnd Regular";
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.500211px;
  color: #000000;
  cursor: pointer;
}
.maincheckbox > p {
  font-family: "Nissan barnd Regular";

  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.500211px;
  color: #000000;
}

.contratobtn > button {
  width: 258px;
  height: 49px;
  background: #c3002f;
  border-radius: 18.375px;
  font-weight: 600;
  font-size: 17.0736px;
  line-height: 14px;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  font-family: "Nissan barnd Regular";
}

.PaymentBtn:disabled {
  background-color: #9f9f9f;
}

.contratobtn > button:disabled {
  background-color: #9f9f9f;
}

.contratobtn {
  width: fit-content;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.maincheckbox > label {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9f9f9f;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.maincheckbox > p {
  padding-bottom: 10px;
}

@media screen and (max-width: 1080px) {
  .carddivmainn {
    height: 100%;
  }
}

@media screen and (max-width: 888px) {
  .carddivmainn {
    width: 100%;
  }

  .carddivmainn {
    height: 600px;
  }

  .carddivmainn > div > h1 {
    font-size: 25px;
  }
}

@media screen and (max-width: 540px) {
  .checkboxcontrato {
    flex-direction: column;
  }

  .carddivmainn {
    padding: 1rem 1.5rem;
    height: 90%;
    align-items: center;
    justify-content: center;
  }

  #scrollcontent > p {
    font-size: 14px;
  }

  .carddivmainn > div > h1 {
    font-size: 20px;
  }
  .maincheckbox > p {
    font-size: 12px;
  }
  .maincheckbox > label > p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 428px) {
  .carddivmainn > div > h1 {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 375px) {
  .carddivmainn {
    padding: 1rem 1.5rem;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .contratocontent {
    height: 290px;
    overflow-y: scroll;
  }
  .contratobtn > button {
    width: 230px;
    height: 35px;
    background: #c3002f;
    border-radius: 18.375px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.035em;
    text-transform: uppercase;
    color: #ffffff;
    border: none;
    font-family: "Nissan barnd Regular";
  }
}
