.inputs4 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.inputs4 > h1 {
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.500211px;
  color: #000000;
  margin-bottom: 0 !important;
}

.inputs4 > p {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.500211px;
  /* color: rgba(255, 255, 255, 0.7); */
  color: #000000;
  align-self: flex-start;
  margin-bottom: 0 !important;
  font-family: "Nissan barnd Regular";
}

#check > span {
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.500211px;
  color: #000000;
  font-family: "Nissan barnd Regular";
}
#check > label {
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.500211px;
  color: #000000;
  cursor: pointer;
}

#check {
  display: flex;
  gap: 1rem;
  flex: 1;
}

/* #check > label {
    display: flex;
    gap: 0.5rem;
  } */

.pra {
  display: flex;
  gap: 1rem;
  cursor: pointer;
}

.pra > p {
  font-family: "Nissan barnd Regular";
  font-size: 9px;
  line-height: 13px;
  text-align: justify;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  width: 475px;
  color: #000000;
  display: flex;
  gap: 1rem;
  align-self: flex-start;
  cursor: pointer;
}

.mainform4 {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  gap: 1rem;
  width: 100% !important;
}

/* checkbox css */

.form-group > input[type="checkbox"] {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid black;
  position: relative;
  left: -5px;
  top: -5px;
  border-radius: 5px;
}

.form-group > input[type="checkbox"]:checked {
  background: #ffffff;
  background-image: url("../../../../assets/images/checboxtick.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
}

.form-group2 > .check > input[type="checkbox"] {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 3px solid black;
  position: relative;
  left: -5px;
  top: -5px;
  border-radius: 5px;
}

.form-group2 > .checkactive > input[type="checkbox"] {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 3px solid black;
  position: relative;
  left: -5px;
  top: -5px;
  border-radius: 5px;
}

.form-group2 > .checkactive > input[type="checkbox"]:checked {
  background: #ffffff;
  background-image: url("../../../../assets/images/checboxtick.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
}

.form-group2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: #000000;
}

.form-group2 > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.form-group2 > div > span {
  color: #000000;
  cursor: pointer;
}

.form-group2 > div {
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
}

.form-group2 > div > label {
  cursor: pointer;
}

/* new checkbox css */

.form-group2 > div > input[type="radio"] {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 3px solid black;
  position: relative;
  left: -5px;
  top: -5px;
  border-radius: 5px;
}

.form-group2 > div > input[type="radio"]:checked {
  /* background:  #666666; */
  background-image: url("../../../../assets/images/checboxtick.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  z-index: 1;
}

@media screen and (max-width: 540px) {
  .inputs4 {
    width: 100%;
  }
  #check > label {
    font-size: 14px;
  }
  .inputs4 > h1 {
    font-size: 20px;
    font-family: "Nissan barnd Bold";
  }

  #check > span {
    font-size: 14px;
  }
  .pra {
    gap: 0.5rem;
  }

  .pra > P {
    width: 100%;
  }
}

@media screen and (max-width: 430px) {
  #check {
    justify-content: space-between;
  }
  #check > span {
    font-size: 12px;
  }

  .form-group2 > div > input[type="radio"] {
    width: 20px;
    height: 20px;
  }

  .form-group2 {
    gap: 0.8rem;
  }
  .form-group2 > div {
    gap: 0.3rem;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 393px) {
  #check > span {
    font-size: 11px;
  }
}
