.inputs6 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}

.khaincard {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.brandlogo > img {
  width: 50px;
}
.brandlogo {
  background-color: #ffffff;
  padding: 0.5rem 1.5rem;
  border-radius: 10px;
}

.getcarddata {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.mainform6 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4rem;
  width: 100% !important;
}

.cardelemnt {
  border: 1px solid;
  width: 100%;
  height: 58px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2px 16px;
  overflow: hidden;
}
.cardelemnt2 {
  width: 100%;
  height: 58px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2px 16px;
  overflow: hidden;
}

.cardelemnt > :nth-child(1) {
  width: 450px;
}
.cardelemnt2 > * {
  width: 100%;
}

.form-error2 {
  color: var(--red);
  font-size: 16px;
  align-self: flex-start;
}

.cardmainelementdiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cardelemnt2 > .maindivinput > div {
  width: 100% !important;
}

@media screen and (max-width: 540px) {
  .inputs6 {
    width: 100%;
  }

  .mainform6 > .inputs6 > div {
    width: 100%;
  }
  .cardelemnt {
    width: 100%;
  }

  .cardelemnt > :nth-child(1) {
    width: 250px;
  }
  .form-error2 {
    font-size: 14px;
  }
}

@media screen and (max-width: 393px) {
  .brandlogo {
    padding: 0.3rem 1rem;
    border-radius: 8px;
  }

  .brandlogo > img {
    width: 35px;
  }
}
