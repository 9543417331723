.OrderHistoryDiv {
  background-color: #efefef !important;
  border-radius: 18px !important;
  width: fit-content !important;
  padding: 18px !important;
}

.additionalSpecs-btn {
  padding: 12px 25px;
  border: none;
  border-radius: 15px;
  background-color: #53545c;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
}
.norecod_text {
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #c3002f; */
}

.OrderText {
  font-family: "Nissan barnd Regular";
  font-weight: 500;
  font-size: 16px;
}
.OrderHead {
  font-family: "Nissan barnd Bold";
  font-weight: 650;
  font-size: 26px;
}

.OrderHistory-parent-div {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  gap: 2rem;
}

.OrderHistory-img-div {
  width: 300px;
}

.ring {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  border: 2px solid #d1d5db;
}

.ringactive {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: #c3002f;
  -webkit-animation: ringfirst 2s infinite alternate-reverse;
  -webkit-animation-fill-mode: both;
  animation-timing-function: linear;
}

.ringfirst {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  -webkit-animation: ringfirst 2s infinite alternate-reverse;
  -webkit-animation-fill-mode: both;
  animation-timing-function: linear;
}

@keyframes ringfirst {
  0% {
    background-color: #c3002f;
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    background-color: #c3002f;
    transform: scale(1.1);
    opacity: 0.9;
  }
  100% {
    background-color: #c3002f;
    transform: scale(1.1);
    opacity: 1;
  }
}

.ordertrack {
  display: flex;
  align-items: center;
  width: 75%;
}
.ordertrack_two {
  display: flex;
  align-items: center;
  width: 100%;
}

.ordertrackmain {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 7px;
  width: 85%;
}

.trackname {
  width: 75%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  gap: 1.7rem;
}
.tracknameee {
  width: 75%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  gap: 1rem;
}

.trackname > h6 {
  font-size: 9px;
}
.tracknameee > h6 {
  font-size: 9px;
  width: 150px;
}

.ringborder {
  width: 71px;
  height: 2px;
}
.newringborder {
  width: 100px;
  height: 2px;
}

@media screen and (max-width: 768px) {
  .OrderHistoryDiv {
    flex-direction: column !important;
  }
  .OrderHistory-parent-div {
    flex-direction: column;
    align-items: center;
  }
  .OrderHistory-img-div {
    width: 60%;
  }
  .norecod_text > h4 {
    font-size: 16px;
  }
}

@media screen and (max-width: 450px) {
  .OrderHistory-img-div {
    width: 90%;
  }
  .ringborder {
    width: 35px;
    height: 2px;
  }

  .trackname {
    width: 75%;
    gap: 1rem;
    /* position: absolute;
    bottom: -40px;
    right: 90px; */
  }

  .trackname > h6 {
    font-size: 7px;
  }

  .ring {
    width: 25px;
    height: 25px;
  }
  .ringactive {
    width: 25px;
    height: 25px;
  }
  .norecod_text > h4 {
    font-size: 16px;
  }
}

@media screen and (max-width: 330px) {
  .ring {
    width: 20px;
    height: 20px;
  }
  .ringactive {
    width: 20px;
    height: 20px;
  }
  .ringborder {
    width: 28px;
  }
  .norecod_text > h4 {
    font-size: 16px;
  }
}
